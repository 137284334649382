import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class OutsideHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnClassToHandle: props.btnClassToHandle 
  };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  toggleClass(wrapper) {
    var children = wrapper.children;
    var element;
    for(var i = 0; i < children.length; i++) {
        element = children[i];
        var activeClass = this.state.btnClassToHandle + " --active";
        if(element.className === activeClass) {
            element.className = "c-menu-dropdown__button";
        }
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.toggleClass(this.wrapperRef);
    }
  }

  render() {
    return <div ref={this.setWrapperRef}>{this.props.children}</div>;
  }
}

OutsideHandler.propTypes = {
  children: PropTypes.element.isRequired,
};