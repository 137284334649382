import React from "react";
import { LocalStorageHelper } from "../../Helpers/LocalStorageHelper";
import Tooltip from "./Legacy/Tooltip/Tooltip";

class Checkbox extends React.Component {
    constructor(props) {
        super(props);

        let storedState = LocalStorageHelper.getValueFromSectionEntry(props.sectionId, props.id);

        if (storedState) {
            this.state = storedState;
        }
        else {
            this.state = {
                checked: props.checked
            };
        }

        this.onChange = this.onChange.bind(this);
    }

    render() {
        return (
            <div className="c-tooltip-wrapper">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        name={this.props.id}
                        checked={this.state.checked}
                        onChange={this.onChange}
                        value={this.props.value} />{this.props.title}
                </label>
                {!!this.props.metaDescription &&
                    <Tooltip content={this.props.metaDescription} />
                }
            </div>
        );
    }

    onChange() {
        this.setState(prevState => ({ checked: !prevState.checked }), () => {
            if (this.props.onChange) {
                this.props.onChange(
                    this.state.checked ? this.props.value : 0, // new val
                    this.state.checked ? 0 : this.props.value // old val
                );

                LocalStorageHelper.updateEntryInSection(this.props.sectionId, this.props.id, this.state);
            }
        });
    }
};

export default Checkbox;