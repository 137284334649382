import * as React from "react";
import { InputNumber } from "./Form/InputNumber";
import { Select } from "./Form/Select";
import { LocalStorageHelper } from "../Helpers/LocalStorageHelper";

export class TripSelect extends React.Component {
    _minValue = 1;

    constructor(props) {
        super(props);

        this.getDefaultState = this.getDefaultState.bind(this);
        this.onTravellersChanged = this.onTravellersChanged.bind(this);
        this.onTripUpdated = this.onTripUpdated.bind(this);
        this.triggerUpdateCallback = this.triggerUpdateCallback.bind(this);

        const existingData = localStorage.getItem(props.dataheader);
        let existingEntries;
        let storedInput;

        if (!!existingData) {
            existingEntries = JSON.parse(existingData);
        }

        if (!!existingEntries && existingEntries.length) {
            storedInput = existingEntries.find((entry) => entry.key === props.dataname);
        }

        if (this.props.travellers.minValue !== undefined && this.props.travellers.minValue !== null) {
            this._minValue = this.props.travellers.minValue;
        }

        this.state = !!storedInput
            ? storedInput.value
            : this.getDefaultState();
    }

    render() {
        return (
            <React.Fragment>
                <InputNumber id={`${this.props.dataname}_travellers`} title={this.props.travellers.title} min={this._minValue} value={this.state.travellers} stepValue={this.props.travellers.stepValue} unit={this.props.travellers.unit} tooltip={this.props.travellers.metaDescription} onChange={this.onTravellersChanged} />
                <Select id={`${this.props.dataname}_trip`} label={this.props.title} value={this.state.selectedTripEmission} options={this.props.options} onSelectChanged={this.onTripUpdated} tooltip={this.props.metaDescription} />
            </React.Fragment>
        );
    }

    onTravellersChanged(count) {
        this.setState({ travellers: count }, this.triggerUpdateCallback);
    }

    onTripUpdated(value) {
        this.setState({ selectedTripEmission: value }, this.triggerUpdateCallback);
    }

    triggerUpdateCallback() {
        const oldValue = this.state.totalEmission;
        const newValue = Number(this.state.selectedTripEmission) * Number(this.state.travellers);

        this.setState({ totalEmission: newValue }, () => {
            LocalStorageHelper.updateEntryInSection(this.props.dataheader, this.props.dataname, this.state);

            if (this.props.onEmissionUpdated) {
                this.props.onEmissionUpdated(newValue, oldValue);
            }
        });
    }

    getDefaultState = () => ({
        travellers: this._minValue,
        selectedTripEmission: 0.0,
        totalEmission: 0.0
    });
}