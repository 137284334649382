import React, { Component } from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import Tooltip from '../Tooltip/Tooltip';



function fetchData(key) {
  var storedData = localStorage.getItem(key);
  if (!storedData) {
    return [];
  }
  return JSON.parse(storedData);
}

class InputNumberLegacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicks: props.clicks,
      emissionfactor: props.emissionfactor,
      metacontrolemissionfactor: props.metacontrolemissionfactor,
      show: true,
      checked: localStorage.getItem('emissionCheckbox_' + props.label),
      header: props.dataheader,
      data: fetchData(props.dataheader),
      key: props.currKey,
      targetValue: props.targetValue
    };

    this.incrementItem = this.incrementItem.bind(this);
    this.decreaseItem = this.decreaseItem.bind(this);
    this.handleEmissionCheckedChange = this.handleEmissionCheckedChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.delayedCallback = debounce(this.delayedHandleInputChange, 10)
  }

  hydrateStateWithLocalStorage(key, label, hydrateProperty) {
    var storedData = localStorage.getItem(key);
    if (storedData) {
      var arr = JSON.parse(storedData);
      var result = arr.find(x => x.key === label);
      if (result) {
        try {
          var value = JSON.parse(result.value);
          this.setState({ [hydrateProperty]: value });
        } catch (e) {
          this.setState({ [hydrateProperty]: value });
        }
      }
    }
  }

  componentDidMount() {
    this.hydrateStateWithLocalStorage(this.props.dataheader, this.props.dataname, "clicks");
  }

  incrementCount(stepValue) {
    this.setState((state) => {
      return { clicks: state.clicks + stepValue }
    });
  }

  decrementCount(stepValue) {
    this.setState((state) => {
      return { clicks: state.clicks - stepValue }
    });
  }

  updateCount(updatedValue) {
    this.setState((state) => {
      return { clicks: updatedValue }
    });
  }

  incrementItem() {
    var decimalCount;
    if (this.state.emissionfactor !== 0 && this.state.emissionfactor.toString().length > 2) {
      decimalCount = this.state.emissionfactor.toString().length - 2;
    }
    this.props.myFunc(Number(this.props.stepValue * this.state.emissionfactor).toFixed(decimalCount), "add");
    this.incrementCount(this.props.stepValue);
    var inputName = this.props.dataname;
    let data = this.state.data;

    if (this.state.data.find(input => input.key === inputName)) {
      var itemIndex = data.findIndex(function (c) {
        return c.key === inputName;
      });

      var updatedItem = update(data[itemIndex], { value: { $set: this.state.clicks + this.props.stepValue } });

      var newData = update(data, {
        $splice: [[itemIndex, 1, updatedItem]]
      });
      data = newData;
    }
    else {
      var newItem = { key: inputName, value: this.state.clicks + this.props.stepValue };
      data.push(newItem);
    }

    var existingEntries = JSON.parse(localStorage.getItem(this.state.header));
    if (existingEntries == null) {
      existingEntries = data;
    }
    else {
      var existingItem = null;
      var entryIndex = 0;
      var hitIndex = 0;
      existingEntries.forEach(entry => {
        if (entry && entry.key === inputName) {
          hitIndex = entryIndex;
          existingItem = entry;
          return existingItem;
        }
        entryIndex++;
      });

      if (existingItem) {
        existingEntries.splice(hitIndex, 1, updatedItem)
      }
      else {
        existingEntries.push(newItem);
      }
    }
    localStorage.setItem(this.state.header, JSON.stringify(existingEntries));
  }

  decreaseItem(e) {

    if (this.state.clicks <= 0) {
      return false;
    }
    var decimalCount;
    if (this.state.emissionfactor !== 0 && this.state.emissionfactor.toString().length > 2) {
      decimalCount = this.state.emissionfactor.toString().length - 2;
    }
    this.props.myFunc(Number(this.props.stepValue * this.state.emissionfactor).toFixed(decimalCount), "subtract");
    this.decrementCount(this.props.stepValue);
    var inputName = this.props.dataname;
    let data = this.state.data;

    var inputValue;

    if (this.state.data.find(input => input.key === inputName)) {
      var itemIndex = data.findIndex(function (c) {
        return c.key === inputName;
      });

      if (this.state.clicks - this.props.stepValue < 0) {
        inputValue = 0;
      } else {
        inputValue = this.state.clicks - this.props.stepValue
      }
      var updatedItem = update(data[itemIndex], { value: { $set: inputValue } });

      var newData = update(data, {
        $splice: [[itemIndex, 1, updatedItem]]
      });
      data = newData;
    }
    else {
      var newItem = { key: inputName, value: this.state.clicks - this.props.stepValue };
      this.state.data.push(newItem);
    }

    var existingEntries = JSON.parse(localStorage.getItem(this.state.header));
    
    if (existingEntries == null) {
      existingEntries = data;
    }
    else {
      var existingItem = null;
      var entryIndex = 0;
      var hitIndex = 0;
      existingEntries.forEach(entry => {
        if (entry.key === inputName) {
          hitIndex = entryIndex;
          existingItem = entry;
          return existingItem;
        }
        entryIndex++;
      });

      if (existingItem) {
        existingEntries.splice(hitIndex, 1, updatedItem)
      }
      else {
        existingEntries.push(newItem);
      }
    }
    localStorage.setItem(this.state.header, JSON.stringify(existingEntries));
  }

  delayedHandleInputChange(event) {
    var targetValue = Number(this.state.targetValue);
    var newValue = targetValue;
    var decimalCount;
    if (this.state.emissionfactor !== 0 && this.state.emissionfactor.toString().length > 2) {
      decimalCount = this.state.emissionfactor.toString().length - 2;
    }
    this.props.updateFunc(Number(newValue * this.state.emissionfactor).toFixed(decimalCount), Number(this.state.clicks * this.state.emissionfactor).toFixed(decimalCount), "update");
    this.updateCount(newValue);

    let data = this.state.data;
    if (this.state.data.find(input => input.key === event.target.dataset.name)) {
      var itemIndex = data.findIndex(function (c) {
        return c.key === event.target.dataset.name;
      });

      var updatedItem = update(data[itemIndex], { value: { $set: newValue } });

      var newData = update(data, {
        $splice: [[itemIndex, 1, updatedItem]]
      });
      data = newData;
    }
    else {
      var newItem = { key: event.target.dataset.name, value: newValue };
      this.state.data.push(newItem);
    }
    var existingEntries = JSON.parse(localStorage.getItem(this.state.header));

    if (existingEntries == null) {
      existingEntries = data;
    } else {
      var existingItem = null;
      var entryIndex = 0;
      var hitIndex = 0;
      existingEntries.forEach(entry => {
        if (entry.key === event.target.dataset.name) {
          hitIndex = entryIndex;
          existingItem = entry;
          return existingItem;
        }
        entryIndex++;
      });
      if (existingItem) {
        existingEntries.splice(hitIndex, 1, updatedItem)
      }
      else {
        existingEntries.push(newItem);
      }
    }
    localStorage.setItem(this.state.header, JSON.stringify(existingEntries));
  }

  handleInputChange(event) {
    event.persist();
    if (event.currentTarget.value < 0) {
      event.currentTarget.value = 0;
    }
    this.setState({ targetValue: event.currentTarget.value });
    this.delayedCallback(event);
  }

  handleInputFocus(e) {
    const target = e.target;
    target.parentElement.classList.toggle("focus");
  }

  handleInputBlur(e) {
    const target = e.target;
    target.parentElement.classList.toggle("focus");
  }

  handleEmissionCheckedChange(event) {
    var emissionCheckedStatus = event.target.checked;

    const selectedEmissionFactor = emissionCheckedStatus
      ? this.props.metacontrolemissionfactor
      : this.props.emissionfactor;

    this.setState({
      checked: emissionCheckedStatus,
      emissionfactor: selectedEmissionFactor
    });

    // Calculate both the emission factor before interacting with the checkbox
    // and after, so that we can update the final section amount properly
    // by removing the "previous" value before adding the next calculated value
    const emissionFactorChecked = (this.state.clicks * this.props.metacontrolemissionfactor);
    const emissionFactorNotChecked = (this.state.clicks * this.props.emissionfactor);

    let decimalChecked = emissionFactorChecked.toString().length - 2;
    let decimalNotChecked = emissionFactorNotChecked.toString().length - 2;

    if (decimalChecked < 0) decimalChecked = 0;
    if (decimalNotChecked < 0) decimalNotChecked = 0;

    if (emissionCheckedStatus) {
      this.props.myFunc(emissionFactorNotChecked, "subtract", decimalNotChecked);

      // Allow recalculations to be applied and states to be updated before adding new value
      setTimeout(() => {
        this.props.myFunc(emissionFactorChecked, "add", decimalChecked);
      }, 10);
    }
    else {
      this.props.myFunc(emissionFactorChecked, "subtract", decimalChecked);

      // Allow recalculations to be applied and states to be updated before adding new value
      setTimeout(() => {
        this.props.myFunc(emissionFactorNotChecked, "add", decimalNotChecked);
      }, 10);
    }

    localStorage.setItem('emissionCheckbox_' + event.target.id, emissionCheckedStatus);
  }

  render() {
    const checkboxId = this.props.dataname + "_" + this.props.label;

    let checkboxText = this.props.checkbox;
    let hasCheckbox = '';
    let tooltipText = this.props.tooltip;
    let tooltipMarkup = '';
    let checked = localStorage.getItem('emissionCheckbox_' + checkboxId);
    if (checked === "true") {
      checked = true;
    }
    else {
      checked = false;
    }
    if (checkboxText != null) {
      hasCheckbox = <label className="checkbox --pull-left" htmlFor={checkboxId}>{this.props.checkbox}<input type="checkbox" id={checkboxId} name={this.props.label} checked={checked} onChange={this.handleEmissionCheckedChange} /></label>;
    }

    if (tooltipText && tooltipText !== "") {
      tooltipMarkup = <Tooltip content={tooltipText}></Tooltip>
    }

    return (
      <div className="input-element">

        <div className="input-element__input">
          <div className="input-btn-icon" onClick={this.decreaseItem}>-</div>
          <div className="input-element-wrapper">
            <div className="input-element__header">
              <label dangerouslySetInnerHTML={{ __html: this.props.label }} htmlFor={this.props.dataname}></label>
              {tooltipMarkup}
            </div>

            <div className="input-control-wrapper">
              <input type="number" min="0" id={this.props.dataname} name={this.props.label} data-name={this.props.dataname} value={this.state.clicks} data-id={this.state.emissionfactor}
                onFocus={this.handleInputFocus.bind(this)}
                onChange={this.handleInputChange.bind(this)}
                onBlur={this.handleInputBlur.bind(this)} />
              <div className="unit">{this.props.unit}</div>
            </div>
            {hasCheckbox}
          </div>
          <div className="input-btn-icon" onClick={this.incrementItem}>+</div>
        </div>
      </div>
    );
  }
}

InputNumberLegacy.protoTypes = {
  myFunc: PropTypes.func,
  updateFunc: PropTypes.func,
};

export default InputNumberLegacy;
