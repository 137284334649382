import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Category from "./components/Category/Category";

import Home from "./components/Home/Home";
import Summary from "./components/Summary/Summary";

import { Global } from "./Constants/Global";
import { TemporaryBanner } from "./components/TemporaryBanner";

class Main extends Component {
    _companyMenuLinks = [
        { url: "/company/energy", text: "Energi" },
        { url: "/company/travel", text: "Resor" },
        { url: "/company/purchases", text: "Inköp &amp; avfall" },
        { url: "/company/event", text: "Evenemang" },
        { separator: true },
        { url: "/company/manual", text: "Ange ton" },
    ];

    _personalMenuLinks = [
        { url: "/personal/energy", text: "Energi" },
        { url: "/personal/travel", text: "Resor" },
        { url: "/personal/purchases", text: "Inköp" },
        { url: "/personal/food", text: "Mat &amp; dryck" },
        { url: "/personal/events", text: "Bröllop &amp; fest" },
        { separator: true },
        { url: "/personal/averageconsumer", text: "Genom&shy;snitts&shy;svensk" },
        { url: "/personal/manual", text: "Ange ton" }
    ];

    constructor(props){
        super(props);
        this.state = {
            isLoading: true, 
            contentData: props.contentData
        };

        // Clear local storage if a major update has been released
        // to ensure the app does not miscalculate based on old data
        const cachedVersion = localStorage.getItem("AppVersion");

        if (!cachedVersion || !cachedVersion.length || cachedVersion !== Global.AppVersion) {
            localStorage.clear();
            localStorage.setItem("AppVersion", Global.AppVersion);
        }
    }

    render() {
        return (
            <div>
                <TemporaryBanner />
                <Switch>
                    <Route exact path="/" render={(props) => <Home {...props} contentData={this.state.contentData} menuLinks={this._companyMenuLinks} />} />
                    <Route path="/company/energy" render={(props) => <Category {...props} key="company-energy" categoryName="energy" nextCategory="travel" parentSegment="company" contentDataKey="company" contentData={this.state.contentData} menuLinks={this._companyMenuLinks} />} />
                    <Route path="/company/travel" render={(props) => <Category {...props} key="company-travel" categoryName="travel" prevCategory="energy" nextCategory="event" parentSegment="company" contentDataKey="company" contentData={this.state.contentData} menuLinks={this._companyMenuLinks} />} />
                    <Route path="/company/purchases" render={(props) => <Category {...props} key="company-purchases" categoryName="purchases" prevCategory="event" nextCategory="manual" parentSegment="company" contentDataKey="company" contentData={this.state.contentData} menuLinks={this._companyMenuLinks} />} />
                    <Route path="/company/event" render={(props) => <Category {...props} key="company-events" categoryName="events" prevCategory="travel" nextCategory="purchases" parentSegment="company" contentDataKey="company" contentData={this.state.contentData} menuLinks={this._companyMenuLinks} />} />
                    <Route path="/company/manual" render={(props) => <Category {...props} key="company-manual" categoryName="manual" prevCategory="purchases" nextCategory="summary" parentSegment="company" contentDataKey="company" contentData={this.state.contentData} menuLinks={this._companyMenuLinks} />} />
                    <Route path="/company/summary" render={(props) => <Summary {...props} contentData={this.state.contentData} customerType="company" menuLinks={this._companyMenuLinks} />} />

                    <Route path="/personal/energy" render={(props) => <Category {...props} key="personal-energy" categoryName="energy" nextCategory="travel" parentSegment="personal" contentDataKey="private" contentData={this.state.contentData} menuLinks={this._personalMenuLinks} />} />
                    <Route path="/personal/travel" render={(props) => <Category {...props} key="personal-travel" categoryName="travel" prevCategory="energy" nextCategory="purchases" parentSegment="personal" contentDataKey="private" contentData={this.state.contentData} menuLinks={this._personalMenuLinks} />} />
                    <Route path="/personal/purchases" render={(props) => <Category {...props} key="personal-purchases" categoryName="purchases" prevCategory="travel" nextCategory="food" parentSegment="personal" contentDataKey="private" contentData={this.state.contentData} menuLinks={this._personalMenuLinks} />} />
                    <Route path="/personal/food" render={(props) => <Category {...props} key="personal-food" categoryName="food" prevCategory="purchases" nextCategory="events" parentSegment="personal" contentDataKey="private" contentData={this.state.contentData} menuLinks={this._personalMenuLinks} />} />
                    <Route path="/personal/events" render={(props) => <Category {...props} key="personal-events" categoryName="events" prevCategory="food" nextCategory="averageconsumer" parentSegment="personal" contentDataKey="private" contentData={this.state.contentData} menuLinks={this._personalMenuLinks} />} />
                    <Route path="/personal/averageconsumer" render={(props) => <Category {...props} key="personal-averageconsumer" categoryName="averageconsumer" prevCategory="events" nextCategory="manual" parentSegment="personal" contentDataKey="private" contentData={this.state.contentData} menuLinks={this._personalMenuLinks} />} />
                    <Route path="/personal/manual" render={(props) => <Category {...props} key="personal-manual" categoryName="manual" prevCategory="averageconsumer" nextCategory="summary" parentSegment="personal" contentDataKey="private" contentData={this.state.contentData} menuLinks={this._personalMenuLinks} />} />
                    <Route path="/personal/summary" render={(props) => <Summary {...props} contentData={this.state.contentData} customerType="private" menuLinks={this._personalMenuLinks} />} />
                </Switch>
            </div>
        );
    }
}

export default Main;