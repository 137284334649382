import React, { Component } from 'react';
import 'core-js';

import Main from './Main';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
        isLoading: true, 
        contentData: ""
    };
}

  componentWillMount() {
    // load in JSON data from file
    var data;
    if(this.state.isLoading) {
      var oReq = new XMLHttpRequest();
      oReq.onload = (e) => {
        data = JSON.parse(oReq.responseText);
        this.setState({
          contentData: data,
          isLoading: false
        });
      };
      oReq.open("get", "/static/data/appContent.json", true);
      oReq.send();
    } 
  }

  render() {
    if(this.state.isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <Main contentData={this.state.contentData} />
      </div>
    );
  }
}

export default App;
