import * as React from "react";

export class GooglePlacesInput extends React.Component {
    _inputRef;
    _searchResults;
    _firstResultRef;
    _service;
    _autocompleteTimeoutHandle;
    _blurSearchResultsTimeoutHandle;
    
    constructor(props) {
        super(props);

        this._inputRef = React.createRef();
        this._searchResults = React.createRef();
        this._googleAttributionsRef = React.createRef();

        this.onCoordinatesUpdated = this.onCoordinatesUpdated.bind(this); 

        this.closestParent = this.closestParent.bind(this);
        this.toggleClosestParentClass = this.toggleClosestParentClass.bind(this);

        this.onChange = this.onChange.bind(this);
        
        this.handlePlacesSearch = this.handlePlacesSearch.bind(this);
        this.showSearchResults = this.showSearchResults.bind(this);
        this.hideSearchResults = this.hideSearchResults.bind(this);

        this.state = {
            value: props.value,
            placeholder: props.placeholder,
            searchResults: [],
            showResults: false,
            waitingForTimer: false,
            loadingResults: false
        };
    }

    render() {
        return (
            <div className="autocomplete">
                <input className="autocomplete__input" ref={this._inputRef} type="text" id={this.props.id} name={this.props.name} value={this.state.value} placeholder={this.state.placeholder} onChange={this.onChange} disabled={this.props.disabled} onFocus={this.showSearchResults} onBlur={this.hideSearchResults} autoComplete="off" />
                <span className={`autocomplete__bar ${this.state.loadingResults ? "autocomplete__bar--loading" : ""}`}></span>
                <div key="autocomplete" ref={this._searchResults} className={`autocomplete__results ${this.state.loadingResults ? "autocomplete__results--loading" : ""} ${this.state.showResults && !!this.state.searchResults.length ? "autocomplete__results--focus" : ""}`}>
                    {this.state.searchResults.map((result, index) => (
                        <button className="autocomplete__result-item" key={index} data-value={result.coordinates} tabIndex={0} onClick={this.onCoordinatesUpdated} onFocus={this.showSearchResults} onBlur={this.hideSearchResults}>{result.name}</button>
                    ))}
                </div>
                <div ref={this._googleAttributionsRef}></div>
            </div>
        );
    }

    componentDidMount() {
        if (!window.google) {
            return;
        }

        if (this.props.onMounted) {
            this.props.onMounted(this._inputRef);
        }

        this._service = new window.google.maps.places.PlacesService(this._googleAttributionsRef.current);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value) {
            this.setState({
                value: nextProps.value,
                placeholder: nextProps.placeholder,
                searchResults: []
            });
        }
    }

    onChange(e) {
        const inputValue = e.currentTarget.value;

        this.setState({
            loadingResults: false,
            waitingForTimer: false,
            value: inputValue
        },
        () => {

            if (!inputValue ||  inputValue.length < 3) {
                return;
            }

            if (!!this._autocompleteTimeoutHandle) {
                clearTimeout(this._autocompleteTimeoutHandle);
            }

            this.setState({ waitingForTimer: true }, () => {
                this._autocompleteTimeoutHandle = setTimeout(() => {
                    const request = {
                        query: this._inputRef.current.value,
                        fields: ["geometry"],
                        type: this.props.locationType
                    };

                    this.setState({ loadingResults: true }, () => this._service.textSearch(request, this.handlePlacesSearch));
                }, 500);
            });
        });
    }

    handlePlacesSearch(results, status) {
        if (status !== "OK") {
            console.error(`Places search for "${this._inputRef.current.value}" failed with status "${status}"`);
            this.setState({ loadingResults: false });
            return;
        }

        const searchResults = [];

        for (const result of results) {
            if (!result.geometry) {
                continue;
            }

            const lat = result.geometry.location.lat();
            const lng = result.geometry.location.lng();
            const coordinates = `${lat},${lng}`;

            searchResults.push({
                name: result.name,
                coordinates
            });
        }

        this.setState({ searchResults, loadingResults: false });
    }

    closestParent(el, fn) {
        return el && (fn(el) ? el : this.closestParent(el.parentNode, fn));
    }

    toggleClosestParentClass(target, className) {
        const parent = this.closestParent(target, function(el) {
            return el.classList.contains(className);
        });
        parent.classList.toggle("focus");
    }

    showSearchResults(e) {
        this.toggleClosestParentClass(e.currentTarget, "input-control-wrapper");

        if (!!this._blurSearchResultsTimeoutHandle) {
            clearTimeout(this._blurSearchResultsTimeoutHandle);
        }

        this.setState({ showResults: true });
    }

    hideSearchResults(e) {
        this.toggleClosestParentClass(e.currentTarget, "input-control-wrapper");
        
        if (!!this._blurSearchResultsTimeoutHandle) {
            clearTimeout(this._blurSearchResultsTimeoutHandle);
        }

        this._blurSearchResultsTimeoutHandle = setTimeout(() => {
            this.setState({ showResults: false });
        }, 200);
    }

    onCoordinatesUpdated(e) {
        e.preventDefault();
        e.nativeEvent.preventDefault();
        const value = e.currentTarget.getAttribute("data-value");

        if (value.indexOf(",") === -1) {
            return;
        }

        const coordinatesArray = value.split(",");
        const coordinates = {
            latitude: parseFloat(coordinatesArray[0]),
            longitude: parseFloat(coordinatesArray[1])
        };

        const place = {
            id: this.props.id,
            name: e.currentTarget.innerText,
            coordinates
        };

        this.setState({
            searchResults: [],
            showResults: false,
            value: place.name,
            placeholder: place.name
        }, () => this.props.onPlaceSelected(place));
    }
}