import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RadioButton3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: localStorage.getItem('radiobutton3'),
      value: 0
    };
  }

  onCheckedChanged(e) {
    const selectedId = e.currentTarget.id;
    const newValue = isNaN(e.currentTarget.value) ? 0 : Number(e.currentTarget.value);

    if (selectedId) {
      this.props.onSelectionChanged(newValue);

      this.setState({
        checked: selectedId,
        value: newValue
      });

      localStorage.setItem('radiobutton3', selectedId);
    }
  }

  render() {
    return (
      <div className="input-group radio col-3">
          <p className="radio-option">
            <input type="radio" name="match" id="radio1" value={this.props.radio1EmissionFactor} checked={this.state.checked === "radio1"} onChange={(e) => this.onCheckedChanged(e)}/>
            <label htmlFor="radio1">
              <span className="title">{this.props.radio1Title}</span>
              <span className="description">{this.props.radio1Desc}</span>
            </label>
          </p>
          <p className="radio-option">
            <input type="radio" name="match" id="radio2" value={this.props.radio2EmissionFactor} checked={this.state.checked === "radio2"} onChange={(e) => this.onCheckedChanged(e)}/>
            <label htmlFor="radio2">
              <span className="title">{this.props.radio2Title}</span>
              <span className="description">{this.props.radio2Desc}</span>
            </label>
          </p>
          <p className="radio-option">
            <input type="radio" name="match" id="radio3" value={this.props.radio3EmissionFactor} checked={this.state.checked === "radio3"} onChange={(e) => this.onCheckedChanged(e)}/>
            <label htmlFor="radio3">
              <span className="title">{this.props.radio3Title}</span>
              <span className="description">{this.props.radio3Desc}</span>
            </label>
          </p>
      </div>
    );
  }
}

RadioButton3.protoTypes = {
  onSelectionChanged: PropTypes.func,
};

export default RadioButton3;
