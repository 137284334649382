import React from "react";
import PropTypes from "prop-types";
import { InputNumber } from "../Form/InputNumber";
import { TravelingRoute } from "../TravelingRoute";
import { TripSelect } from "../TripSelect";
import { LocalStorageHelper } from "../../Helpers/LocalStorageHelper";
import Checkbox from "../Form/Checkbox";

class CategorySubSection extends React.Component {
    constructor(props) {
        super(props);

        const storedState = LocalStorageHelper.getValueFromSectionEntry(props.sectionId, props.id);

        this.state = !!storedState
            ? storedState
            : {
                emissionSum: 0,
                factor: 1
            };

        this.onControlChanged = this.onControlChanged.bind(this);
        this.onFactorControlChanged = this.onFactorControlChanged.bind(this);
    }

    render() {
        const controlElements = [];

        for (let i = 0; i < this.props.controls.length; i++) {
            const control = this.props.controls[i];
            const controlId = `${this.props.id}_${control.id}_${control.type}_${i}`;

            let component;

            switch (control.type) {
                default:
                    component = null;
                    break;

                case "number" || "text":
                    component = <InputNumber key={controlId} id={controlId} name={controlId} sectionId={this.props.sectionId} onChange={this.onControlChanged} {...control} />;
                    break;

                case "factor":
                    component = <InputNumber key={controlId} id={controlId} name={controlId} sectionId={this.props.sectionId} onChange={this.onFactorControlChanged} {...control} />;
                    break;

                case "text":
                    component = <InputNumber key={controlId} id={controlId} name={controlId} sectionId={this.props.sectionId} onChange={this.onControlChanged} {...control} />;
                    break;

                case "route":
                    component = <TravelingRoute key={controlId} dataheader={controlId} dataname={controlId} sectionId={this.props.sectionId} onEmissionUpdated={this.onControlChanged} {...control} />;
                    break;

                case "tripselect":
                    component = <TripSelect key={controlId} dataname={controlId} dataheader={controlId} sectionId={this.props.sectionId} onEmissionUpdated={this.onControlChanged} {...control} />;
                    break;

                case "check":
                    component =
                        <Checkbox
                            {...control}
                            key={controlId}
                            id={controlId}
                            sectionId={this.props.sectionId}
                            title={control.title}
                            metaDescription={control.metaDescription}
                            value={control.emissionFactor}
                            checked={false}
                            onChange={this.onControlChanged}
                        />;
                    break;

                case "radio":
                    // WIP, currently only used if a section has "special template" which has not been refactored yet
                    break;
                    
                case "divider":
                    component = <h4 key={controlId} className="control-divider">{control.title}</h4>;
                    break;
            };

            if (component) {
                controlElements.push(component);
            }
        }

        return (
            <div>
                {!!(this.props.title && this.props.title.length) && <div className="input-group-header">{this.props.title}</div>}
                <div className="input-group">
                    {controlElements}
                </div>
            </div>
        );
    }

    /** Recalculate subsection total when a control value has been updated */
    onControlChanged(newControlValue, oldControlValue) {
        const oldSum = this.state.emissionSum;
        const newSum = isNaN(oldControlValue) ? newControlValue : oldSum - oldControlValue + newControlValue;

        const oldTotal = oldSum * this.state.factor;
        const newTotal = newSum * this.state.factor;

        this.setState({ emissionSum: newSum }, () => {
            if (this.props.onEmissionUpdated) {
                this.props.onEmissionUpdated(newTotal, oldTotal);
            }
        });
    }

    /** Recalculates the subsection total based on an updated multiplier */
    onFactorControlChanged(factor) {
        const oldFactor = this.state.factor;
        const oldSum = this.state.emissionSum * oldFactor;

        this.setState({ factor }, () => {
            LocalStorageHelper.updateEntryInSection(this.props.sectionId, this.props.id, this.state);

            if (this.props.onEmissionUpdated) {
                this.props.onEmissionUpdated(this.state.emissionSum * this.state.factor, oldSum);
            }
        });
    }
}

CategorySubSection.protoTypes = {
    id: PropTypes.string,
    sectionId: PropTypes.string,
    title: PropTypes.string,
    controls: PropTypes.object,
    onEmissionUpdated: PropTypes.func
};

export default CategorySubSection;