import * as React from "react";

/**
 * Temporary banner according to support ticket:
 * https://tedgustaf.atlassian.net/browse/SUPPORT-1807
 * 
 * Hard coded since it has a very specific use-case and limited life time.
 */
export const TemporaryBanner = () => {
  // Check if user has clicked the "I understand" button, if so do not show the banner. Keep in session storage.
  const [showBanner, setShowBanner] = React.useState(true);

  // Check in session storage if user has clicked the "I understand" button
  React.useEffect(() => {
    const storage = sessionStorage.getItem("temporaryBanner");
    if (storage === "false") {
      setShowBanner(false);
    }
  }, []);

  // Hide the banner and store in session storage that the user has clicked the "I understand" button
  const handleHideBanner = () => {
    setShowBanner(false);
    sessionStorage.setItem("temporaryBanner", "false");
  }

  const lightboxStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 8000
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "center",
    backgroundColor: "#f2efd8",
    padding: "1.6rem 1.2rem",
    textAlign: "left",
    fontSize: "1.4rem",
    zIndex: 9000
  };

  return showBanner && (
    <div style={lightboxStyle}>
      <div style={modalStyle}>
        Tyvärr har vi pausat vår försäljning av kolkrediter på grund av förseningar i verifikationsprocessen.
        Det går fortfarande bra att göra uträkningar i vår klimatkalkylator men inte klimatkompensera med oss i nuläget.
        Försäljningen kommer komma igång tidigast i december 2024.

        <button onClick={handleHideBanner} className="btn-path" style={{border: "none"}}>Jag förstår</button>
      </div>
    </div>
  );
};
