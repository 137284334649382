import React, { Component } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';

class CategoryCart extends Component {
  constructor(props) {
      super(props)
      this.state = {
          contentData: props.contentData,
          nextUrl: props.nextUrl,
          sectionCost: props.sectionCost,
          sectionAmount: props.sectionAmount,
          dataHeader: props.dataHeader,
          redirect: false,
          disabledLink: true
      }
      this.handleCartClick = this.handleCartClick.bind(this);
  }

  hydrateStateWithLocalStorage(key, label, hydrateProperty) { 
    var result = localStorage.getItem(key);
    if (result) {
      try {
        var value = result;
        this.setState({ [hydrateProperty]: value });
      } catch (e) {
        this.setState({ [hydrateProperty]: value });
      }
    }
  }

  componentDidMount() {
    var header = this.state.dataHeader;
    this.hydrateStateWithLocalStorage(header+'-sectionAmount', header+'-sectionAmount', 'sectionAmount');
    this.hydrateStateWithLocalStorage(header+'-sectionCost', header+'-sectionCost', 'sectionCost');
  }

  componentWillReceiveProps(nextProps) {
    this.setState({sectionCost: nextProps.sectionCost});
    this.setState({sectionAmount: nextProps.sectionAmount});
  }

  getPriceFromPriceTable(amount) {
    var priceTableData = this.state.contentData.priceTable.tiers;
    var price = 0;
    priceTableData.forEach(tier => {
      if(tier.max !== -1 && tier.min <= amount && amount <= tier.max) {
        price = tier.price;
        return price;
      }
      else if(tier.min < amount && tier.max === -1) {
        price = tier.price;
        return price;
      }
    });
    return price;
}

  handleCartClick() {
    var pathName = this.context.router.route.location.pathname;
    var filterValue;
    if(pathName.includes('company')) {
      filterValue = "company";
    }
    else {
      filterValue = "personal";
    }

    var sectionNameToSlice = "/"+filterValue+"/";
    var sliceLength = sectionNameToSlice.length;
    var currentSectionName = pathName.slice(sliceLength, pathName.length);

    if(this.state.sectionAmount && this.state.sectionCost) {
      var arr = [];
      var inCartArray = [];
      for (var i = 0; i < localStorage.length; i++){
          var sectionAmount = "sectionAmount";
          //var sectionCost = "sectionCost";
          var tempValue = localStorage.key(i);
          if (tempValue.includes(filterValue) && tempValue.includes(sectionAmount) && !tempValue.includes("-inCart")) {
              var key = localStorage.key(i);
              var value = localStorage[key];

              //localStorage.setItem(inCartStorageName, true);
              //om det är en sektion som är inCart === true eller nuvarande sektion ska den pushas in i arr.
              
              var inCartFromStorage = localStorage.getItem(key+"-inCart");
              var sectionExistsInCart = false;
              if(inCartFromStorage && inCartFromStorage === "true") {
                sectionExistsInCart = true;
              }

              // var inCartStorageName = key + "-inCart";
              // var inCartItem = { "key": inCartStorageName, "value": true }
              // inCartArray.push(inCartItem);
              //var existingSectionInCart.value ? true : false;

              if(sectionExistsInCart || tempValue.includes(currentSectionName)) {
                arr.push(value);
                var inCartStorageName = key + "-inCart";
                var inCartItem = { "key": inCartStorageName, "value": true }
                inCartArray.push(inCartItem);
              }
          }
      }

      if(arr && arr.length > 0)
      {
        var totalAmount = 0;
        for(var y = 0; y < arr.length; y++) {
          totalAmount = totalAmount + Number(arr[y]);
        }
        localStorage.setItem('totalAmount', totalAmount);
        var totAmount;
        if(totalAmount < 1.0) {
          totAmount = Math.ceil(totalAmount);
        }
        else {
          totAmount = totalAmount;
        }

        var totalCost = ((totalAmount * this.getPriceFromPriceTable(totAmount))*1.25);
        localStorage.setItem('totalCost', totalCost);
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; // January is 0!
        if (dd < 10) {
          dd = '0' + dd;
        } 
        if (mm < 10) {
          mm = '0' + mm;
        }
        var dataRetention = this.state.contentData.global.dataRetention;
        var numberOfDaysToAdd = (Number(dataRetention)/24);
        var newDay = new Date();
        newDay.setDate(today.getDate() + numberOfDaysToAdd);
        var newM = newDay.getMonth() + 1;
        var newY = newDay.getFullYear();
        if(newDay.getDate() < today.getDate()) {
          if(newDay.getMonth() === 12)
          {
            newY = newDay.getFullYear() + 1;
            newM = newDay.setMonth(0);
          }
          else {
            newM = newDay.getMonth() + 1;
          }
        }
        var newD = newDay.getDate();
        if(newM < 10) {
          newM = '0' + newM;
        }
        if(newD < 10) {
          newD = '0' + newD;
        }
        var retentionDate = newD + '/' + newM + '/' + newY;
        localStorage.setItem('dataRetention', retentionDate)
      }
      if((inCartArray && inCartArray.length > 0)) {
        for(var o = 0; o < inCartArray.length; o++) {
          var itemToSaveInLocalStorageKey = inCartArray[o].key;
          var itemToSaveInLocalStorageValue = inCartArray[o].value;
          localStorage.setItem(itemToSaveInLocalStorageKey, itemToSaveInLocalStorageValue);
        }
      }
      this.scrollToTop(500);
    }
    this.setState({redirect: true});
  }

  scrollToTop(scrollDuration) {
    var cosParameter = window.scrollY / 2,
        scrollCount = 0,
        oldTimestamp = performance.now();
    function step (newTimestamp) {
        scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
        if (scrollCount >= Math.PI) window.scrollTo(0, 0);
        if (window.scrollY === 0) return;
        window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
  }
  
  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.props.nextUrl} />;
    }

    let linkClass = "disabled-link btn btn-cta";
    if(this.state.sectionAmount && this.state.sectionCost) {
      linkClass = "btn btn-cta";
    }

    return (
      <div className="category-cart">
        <div className="cart-row co2">
          <div className="cart-unit">CO<sub>2</sub>e</div>
          <div className="cart-amount">{parseFloat(this.state.sectionAmount).toFixed(2).toLocaleString("sv")} <span>ton</span></div>
        </div>
        <div className="cart-row price">
          <div className="cart-unit">Pris</div>
          <div className="cart-amount">{parseFloat((this.state.sectionCost * 1.25)).toFixed(2).toLocaleString("sv")} <span>kr<span>*</span></span></div>
        </div>
        <div className="cart-row cart-info">
          <div><span>*</span>inkl. 25% moms</div>
        </div>
        {/* eslint-disable-next-line */}
        <button type="button" onClick={this.handleCartClick} className={linkClass}>
          Lägg i varukorgen
          <small>och gå till nästa kategori</small>
        </button>
      </div>
    );
  }
}

CategoryCart.contextTypes = { 
  router: PropTypes.object, 
  location: PropTypes.object
};

export default CategoryCart;
