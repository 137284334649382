import React, { Component } from 'react';
import PropTypes from 'prop-types';

import iconClose from '../../../shared/images/svg/icon-close.svg';
import arrowRight from '../../../shared/images/svg/btn-arrow-right.svg';
import arrow from '../../../shared/images/svg/icon-arrow-outlined.svg';

class CartDropdown2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contentData: props.contentData,
            nextUrl: props.nextUrl,
            updated: false,
            open: this.props.open,
            totalCost: this.props.totalCost,
            totalAmount: this.props.totalAmount,
            totalVat: this.props.totalVat
        };
        this.removeSectionFromStorage = this.removeSectionFromStorage.bind(this);   
        this.toggleVisible = this.toggleVisible.bind(this);
    }

    getPriceFromPriceTable(amount) {
        var priceTableData = this.state.contentData.priceTable.tiers;
        var price = 0;
        priceTableData.forEach(tier => {
          if(tier.max !== -1 && tier.min <= amount && amount <= tier.max) {
            price = tier.price;
            return price;
          }
          else if(tier.min < amount && tier.max === -1) {
            price = tier.price;
            return price;
          }
        });
        return price;
    }
    
    removeSectionFromStorage(sectionAmount, sectionCost, section) {
        localStorage.removeItem(sectionAmount);
        localStorage.removeItem(sectionAmount+"-inCart");
        localStorage.removeItem(sectionCost);
        localStorage.removeItem(section);

        let categories = [];
        let arr = [];
        let pathName = this.context.router.route.location.pathname;
        let sectionPath = "";

        if(pathName.includes('company')) {
            sectionPath = "company-";
            categories = this.state.contentData.company.categories;
        }
        else {
            sectionPath = "personal-";
            categories = this.state.contentData.private.categories;
        }

        let sectionAmountInLocalStorage;
        let sectionCostInLocalStorage;

        for (const category of categories) {
            let categoryName = category.categoryName;
            sectionAmountInLocalStorage = sectionPath + categoryName + '-sectionAmount';
            sectionCostInLocalStorage = sectionPath + categoryName + '-sectionCost';
    
            let sectionAmountFound = localStorage.getItem(sectionAmountInLocalStorage);
            let sectionCostFound = localStorage.getItem(sectionCostInLocalStorage);
            let sectionAmountInCartFromStorage = localStorage.getItem(sectionAmountInLocalStorage+"-inCart") === "true" ? true : false ;
            if(sectionAmountFound && sectionCostFound && sectionAmountInCartFromStorage) {
                let addExistingSection = { amount: sectionAmountFound, cost: sectionCostFound };
                arr.push(addExistingSection);
            }
        }

        categories.forEach(category => {
        });

        if(arr && arr.length > 0)
        {
            let totalAmount = 0;
            for(let y = 0; y < arr.length; y++) {
                totalAmount = totalAmount + Number(arr[y].amount);
            }

            localStorage.setItem('totalAmount', totalAmount);

            let totAmount;

            if(totalAmount < 1.0) {
                totAmount = Math.ceil(totalAmount);
            }
            else {
                totAmount = totalAmount;
            }

            let totalCost = ((totalAmount * this.getPriceFromPriceTable(totAmount))*1.25);
            localStorage.setItem('totalCost', totalCost);
        }
        else {
            localStorage.removeItem('totalCost');
            localStorage.removeItem('totalAmount');
        }
        
        this.setState({ updated: true });
    }

    toggleVisible() {
        this.setState({ open: !this.state.open });
    }

    renderCartSections(dataSections, removeSectionFromStorage, sectionPath, hrefPath) {
        let sections = [];
        let rows = [];
        let y = 0;

        let sectionAmountInLocalStorage;
        let sectionCostInLocalStorage;
        let sectionNameInLocalStorage;
        let categories = dataSections;
        let removeSection = removeSectionFromStorage;
                
        for (const category of categories) {
            let categoryName = category.categoryName;
            sectionAmountInLocalStorage = sectionPath + categoryName + '-sectionAmount';
            sectionCostInLocalStorage = sectionPath + categoryName + '-sectionCost';
            sectionNameInLocalStorage = sectionPath + categoryName; 
    
            let sectionAmountFound = localStorage.getItem(sectionAmountInLocalStorage);
            let sectionCostFound = localStorage.getItem(sectionCostInLocalStorage);
            let sectionFound = localStorage.getItem(sectionNameInLocalStorage);
            let sectionAmountInCartFromStorage = localStorage.getItem(sectionAmountInLocalStorage+"-inCart") === "true" ? true : false ;

            if(sectionNameInLocalStorage === "personal-averageconsumer" || sectionNameInLocalStorage === "personal-purchases") {
                sectionFound = true; //fix for empty section in avergageconsumer and personal-purchases.
            }

            if(sectionAmountFound && sectionCostFound && sectionFound && (sectionAmountInCartFromStorage === true)) {
                let fullHrefPath = hrefPath + category.categoryName;
                let addExistingSection = { title: category.title, amount: sectionAmountFound, cost: sectionCostFound, href: fullHrefPath, sectionAmountFromStorage: sectionAmountInLocalStorage, sectionCostFromStorage: sectionCostInLocalStorage, sectionFromStorage: sectionNameInLocalStorage };
                sections.push(addExistingSection);
            }
        }

        for (const section of sections) {
            let sectionCostIncVat = (Number(section.cost)*1.25); 
            let secTitle = "Gå till " + section.title;
            let sectionAmountFixed = Number(section.amount).toFixed(2);
            let sectionCostIncVatFixed = Number(sectionCostIncVat).toFixed(2);
            
            rows.push(
                <tr key={['trrow', y].join('_')}>
                    <td><a href={section.href} title={secTitle}>{section.title}</a></td>
                    <td className="u-text-right u-text-nowrap">{parseFloat(sectionAmountFixed).toLocaleString("sv")}</td>
                    <td className="u-text-right u-text-nowrap">{parseFloat(sectionCostIncVatFixed).toLocaleString("sv")}</td>
                    <td>
                        <div className="c-summary__remove-item" onClick={() => removeSection(section.sectionAmountFromStorage, section.sectionCostFromStorage, section.sectionFromStorage)}>
                            <img src={iconClose} alt="Ta bort" title="Ta bort denna rad" />
                        </div>
                    </td>
                </tr>
            );

            y++;
        }

        return rows;
    }

    renderTotalSection(categories, sectionPath, hrefPath) {
        let totalCost = 0;
        let totalAmount = 0;
        let totalVat = 0;
        var sections = [];
        var sectionAmountInLocalStorage;
        var sectionCostInLocalStorage;
        categories.forEach(category => {
            var categoryName = category.categoryName;
            sectionAmountInLocalStorage = sectionPath + categoryName + '-sectionAmount';
            sectionCostInLocalStorage = sectionPath + categoryName + '-sectionCost';
            var sectionAmountInCartFromStorage = localStorage.getItem(sectionAmountInLocalStorage+"-inCart") === "true" ? true : false ;
    
            var sectionAmountFound = localStorage.getItem(sectionAmountInLocalStorage);
            var sectionCostFound = localStorage.getItem(sectionCostInLocalStorage);
            if(sectionAmountFound && sectionCostFound && sectionAmountInCartFromStorage) {
                var fullHrefPath = hrefPath + category.categoryName;
                var addExistingSection = { title: category.title, amount: sectionAmountFound, cost: sectionCostFound, href: fullHrefPath, sectionAmountFromStorage: sectionAmountInLocalStorage, sectionCostFromStorage: sectionCostInLocalStorage };
                sections.push(addExistingSection);
            }
        });
        sections.forEach(section => {
            var sectionCostIncVat = (Number(section.cost)*1.25);
            totalAmount = totalAmount + Number(section.amount);
            totalCost = totalCost + sectionCostIncVat;
        });
        var totalRoundedAmount;
        if(totalAmount < 1) {
            totalRoundedAmount = Math.ceil(totalAmount);
        }
        else {
            totalRoundedAmount = Math.round(totalAmount);
        }

        var priceFromPriceTable = this.getPriceFromPriceTable(totalRoundedAmount)
        totalCost = (((totalRoundedAmount * priceFromPriceTable) * 1.25));

        totalVat = (totalCost * 0.2);
        totalVat = totalVat.toFixed(2);
        var totalRoundedAmountFixed = Number(totalRoundedAmount).toFixed(2);
        var totalCostFixed = Number(totalCost).toFixed(2);

        var arr = [<tr  key="trtotal">
        <td><span>Avrundning*</span></td>
        <td className="u-text-right u-text-nowrap"><span>{parseFloat(totalRoundedAmountFixed).toLocaleString("sv")}</span></td>
        <td className="u-text-right u-text-nowrap"><span>{parseFloat(totalCostFixed).toLocaleString("sv")}</span></td>
        <td><span>&nbsp;</span></td>
        </tr>,
        <tr key="trrowfirst">
        <td><strong>Total</strong></td>
        <td colSpan="2" className="u-text-right u-text-nowrap"><strong>{parseFloat(totalCostFixed).toLocaleString("sv")} kr</strong></td>
        <td>&nbsp;</td>
        </tr>,
        <tr key="trrowsecond" className="vat">
            <td colSpan="2">Varav moms 25%</td>
            <td className="u-text-right u-text-nowrap">{parseFloat(totalVat).toLocaleString("sv")} kr</td>
            <td>&nbsp;</td>
        </tr>,
        <tr key="trrowthird">
            <td colSpan="4"><small>*Avrundat till närmaste hela ton</small></td>
        </tr>];
        
        return arr;
    }

    render() {    
        var categories = [];
        var pathName = this.context.router.route.location.pathname;
        var hrefPath = "";
        var sectionPath = "";

        var totalAmountFound = localStorage.getItem('totalAmount');
        var totalCostFound = localStorage.getItem('totalCost');

        if(totalAmountFound && totalCostFound) {
            totalAmountFound = Number(totalAmountFound);
            totalCostFound = Number(totalCostFound);
        }
        else {
            totalAmountFound = 0;
            totalCostFound = 0;
        }

        if(pathName.includes('company'))
        {
            hrefPath = "/company/";
            sectionPath = "company-"
            categories = this.state.contentData.company.categories;
        }
        else {
            hrefPath = "/personal/";
            sectionPath = "personal-";
            categories = this.state.contentData.private.categories;
        }

        var summaryPath = hrefPath+'summary';
        var totalAmountFoundFixed = Number(totalAmountFound.toFixed(2));
        var totalCostFoundFixed = Number(totalCostFound.toFixed(2));
        return (
        [<button key="dropdown_btn" onClick={this.toggleVisible} className= { this.state.open ? "c-menu-dropdown__button --active" : "c-menu-dropdown__button"} ><span>{parseFloat(totalAmountFoundFixed).toLocaleString("sv")} ton CO<sub>2</sub>e</span> {parseFloat(totalCostFoundFixed).toLocaleString("sv")} kr
        <svg className="c-icon" role="img">
            <use xlinkHref={`${arrow}#icon-arrow-outlined`}></use>
        </svg>
        </button>,
        <div key="dropdown_panel" className="c-menu-dropdown__panel">
            <table className="c-table">
            <thead>
                <tr>
                    <th>Kategori</th>
                    <th className="u-text-right">Ton CO<sub>2</sub>e</th>
                    <th className="u-text-right">Pris</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {this.renderCartSections(categories, this.removeSectionFromStorage, sectionPath, hrefPath)}
            </tbody>
            <tfoot>
                {this.renderTotalSection(categories, sectionPath, hrefPath)}
            </tfoot>
            </table>
            <div className="c-menu-dropdown__pay">
            <a href={summaryPath} className="btn btn-cta btn-icon">
                Gå till varukorgen 
                <svg className="c-icon c-icon-arrow" role="img">
                    <use xlinkHref={`${arrowRight}#btn-right-right`}></use>
                </svg>
            </a>
            </div>
        </div>]
    );
    }
}

CartDropdown2.contextTypes = { 
    router: PropTypes.object, 
    location: PropTypes.object
};

export default CartDropdown2;
