import React from "react";

import Tooltip from "./Legacy/Tooltip/Tooltip";
import { LocalStorageHelper } from "../../Helpers/LocalStorageHelper";

export class InputNumber extends React.Component {
    _minValue;
    _defaultValue;
    _defaultFactor;

    _tempOldValue;
    _tempOldFactor;

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onDecrease = this.onDecrease.bind(this);
        this.onIncrease = this.onIncrease.bind(this);
        this.onFactorChanged = this.onFactorChanged.bind(this);
        this.triggerChangeCallback = this.triggerChangeCallback.bind(this);
        this.savePreviousValues = this.savePreviousValues.bind(this);

        this._minValue = !isNaN(props.min) ? Number(props.min) : 0;

        this._defaultValue = this._minValue;

        this._defaultFactor = props.emissionFactor !== undefined && props.emissionFactor !== null
            ? props.emissionFactor
            : 1;

        let storedState = LocalStorageHelper.getValueFromSectionEntry(props.sectionId, props.id);

        if (storedState) {
            this.state = storedState;
        }
        else {
            this.state = {
                placeholder: null,
                value: this._defaultValue,
                factor: Number(this._defaultFactor),
                alternativeFactorChecked: false
            };
        }

        this._tempOldValue = this.state.value;
        this._tempOldFactor = this.state.factor;
    }

    render() {
        const tooltip = this.props.tooltip && this.props.tooltip.length
            ? this.props.tooltip
            : this.props.metaDescription;

        return (
            <div className="input-element">
                <div className="input-element__input">
                    <div className="input-btn-icon" onClick={this.onDecrease}>-</div>
                    <div className="input-element-wrapper">
                        <div className="input-element__header">
                            {!!this.props.title &&
                                <label dangerouslySetInnerHTML={{ __html: this.props.title }} htmlFor={this.props.id}></label>
                            }
                            {!!tooltip &&
                                <Tooltip content={tooltip} />
                            }
                        </div>
                        <div className="input-control-wrapper">
                            <input type="number"
                                min={this._minValue}
                                id={this.props.id}
                                name={this.props.name}
                                data-name={this.props.dataName}
                                data-id={this.props.dataId}
                                placeholder={this.state.placeholder}
                                value={this.state.value}
                                onChange={this.onChange}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur} />
                            {!!this.props.unit &&
                                <div className="unit">{this.props.unit}</div>
                            }
                        </div>
                        {!!this.props.metaControl && (
                            <label className="checkbox --pull-left" htmlFor={`${this.props.id}_factor`}>
                                {this.props.metaControl.title}<input type="checkbox" id={`${this.props.id}_factor`} name={this.props.metaControl.title} checked={this.state.alternativeFactorChecked} onChange={this.onFactorChanged} />
                            </label>
                        )}
                    </div>
                    <div className="input-btn-icon" onClick={this.onIncrease}>+</div>
                </div>
            </div>
        );
    }

    onChange(e) {
        this.savePreviousValues();
        let value = e.currentTarget.value.length ? Number(e.currentTarget.value) : "";
        let placeholder = "";

        if (value !== "" && value < this._minValue) {
            value = this._minValue;
        }
        // Allow empty input, store actual value temporarily in placeholder
        else if (value === "") {
            placeholder = this._minValue;
        }
        
        this.setState({ value, placeholder }, this.triggerChangeCallback);
    }

    onFocus(e) {
        const target = e.currentTarget;
        target.parentElement.classList.toggle("focus");

        const currentValue = this.state.value;

        if (currentValue === this._minValue) {
            this.setState({
                placeholder: currentValue,
                value: ""
            });
        }
    }
    
    onBlur(e) {
        const target = e.currentTarget;
        target.parentElement.classList.toggle("focus");

        // Make sure we reset the actual value when focus is no longer on the input
        // and clear the placeholder
        if (this.state.value === "" && this.state.placeholder !== null) {
            const value = Number(this.state.placeholder);

            this.setState({
                placeholder: null,
                value
            });
        }
    }

    onDecrease() {
        this.savePreviousValues();
        let value = this.state.value - this.props.stepValue;

        if (isNaN(value) ||  value < this._minValue) {
            value = this._minValue;
        }

        this.setState({ value }, this.triggerChangeCallback);
    }

    onIncrease() {
        this.savePreviousValues();
        const value = this.state.value + this.props.stepValue;

        this.setState({ value }, this.triggerChangeCallback);
    }

    onFactorChanged() {
        this.savePreviousValues();
        const isChecked = this.state.alternativeFactorChecked;
        const newCheckState = !isChecked;
        const alternativeFactor = this.props.metaControl.emissionFactor;

        this.setState({
            factor: newCheckState ? Number(alternativeFactor) : Number(this._defaultFactor),
            alternativeFactorChecked: newCheckState
        }, this.triggerChangeCallback);
    }

    triggerChangeCallback() {
        let value = this.state.value;

        // Handle empty value - since we use placeholder to "store" a proper value to allow the user to clear the input,
        // we also need to use it as a fallback in the callback
        if (value === "" && this.state.placeholder !== "") {
            value = this.state.placeholder;
        }

        const oldTotal = Number(this._tempOldValue) * Number(this._tempOldFactor);
        const newTotal = Number(value) * Number(this.state.factor);

        if (this.props.onChange) {
            this.props.onChange(newTotal, oldTotal);
        }
        
        LocalStorageHelper.updateEntryInSection(this.props.sectionId, this.props.id, this.state);
    }

    /** Temporarily saves current values as "previous" values, to ensure calculations are correct */
    savePreviousValues() {
        this._tempOldValue = this.state.value;
        this._tempOldFactor = this.state.factor;
    }
}