import * as React from "react";
import { GooglePlacesInput } from "./Form/GooglePlacesInput";
import { FlightDistanceCalculator } from "../Services/FlightDistanceCalculator";
import { InputNumber } from "./Form/InputNumber";
import { LocalStorageHelper } from "../Helpers/LocalStorageHelper";
import RadioButtonList from "./Form/RadioButtonList";

class TravelLocation extends React.Component {
    constructor(props) {
        super(props);

        this.onRemove = this.onRemove.bind(this);
    }

    render() {
        return (
            <div className="input-element__input">
                <div className="input-element-wrapper">
                    {!!this.props.label && (
                        <div className="input-element__header">
                            <label htmlFor={this.props.id}>{this.props.label}</label>
                        </div>
                    )}
                    <div className="input-control-wrapper">
                        <GooglePlacesInput id={this.props.id} onPlaceSelected={this.props.onPlaceSelected} locationType={this.props.locationType} value={this.props.value && this.props.value.length ? this.props.value : ""} placeholder={this.props.placeholder} disabled={this.props.disabled} />
                    </div>
                    {this.props.onRemove &&
                        <button type="button" className="input-btn-remove" onClick={this.onRemove}></button>
                    }
                </div>
            </div>
        );
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.id);
        }
    }
}


export class TravelingRoute extends React.Component {
    constructor(props) {
        super(props);

        this.getDefaultState = this.getDefaultState.bind(this);
        this.resetState = this.resetState.bind(this);

        this.renderRoundTripOptions = this.renderRoundTripOptions.bind(this);

        this.addStop = this.addStop.bind(this);
        this.removeStop = this.removeStop.bind(this);
        
        this.calculateEmission = this.calculateEmission.bind(this);
        this.getEmissionFactor = this.getEmissionFactor.bind(this);

        this.onOriginSelected = this.onOriginSelected.bind(this);
        this.onFinalDestinationSelected = this.onFinalDestinationSelected.bind(this);
        this.onPlaceSelected = this.onPlaceSelected.bind(this);
        this.onRouteUpdated = this.onRouteUpdated.bind(this);
        this.onTravellersUpdated = this.onTravellersUpdated.bind(this);
        this.onRoundTripChanged = this.onRoundTripChanged.bind(this);

        const storedState = LocalStorageHelper.getValueFromSectionEntry(props.sectionId, props.id);

        this.state = !!storedState
            ? storedState
            : this.getDefaultState();
    }

    render() {
        return (
            <div className={`c-traveling-route ${(!this.props.travellers ? "s--justify-end" : "")}`}>
                {!!this.props.travellers &&
                    <InputNumber id={this.props.id + "__travellers"} name={this.props.id + "__travellers"} {...this.props.travellers} min={1} value={this.state.travellers} sectionId={this.props.sectionId} onChange={this.onTravellersUpdated} />
                }

                {!!this.props.resetText &&
                    <button type="button" onClick={this.resetState} className="btn btn-outline btn-clear">{this.props.resetText}</button>
                }

                {this.renderRoundTripOptions()}

                <div className="input-group__row input-group__row--adjust-sides">
                    <TravelLocation id={`${this.props.id}__origin`} name={`${this.props.id}__origin`} label={this.props.originTitle} locationType={this.props.locationType} placeholder={this.props.placeholder} onPlaceSelected={this.onOriginSelected} value={this.state.origin} />
                </div>

                {this.props.enableStops && (
                    <React.Fragment>
                        {!!this.state.stopInputs && !!this.state.stopInputs.length &&
                            <div className="input-group__row input-group__row--adjust-sides">
                                {this.state.stopInputs.map((input) => (
                                    <TravelLocation key={input.key} {...input} locationType={this.props.locationType} value={input.value} placeholder={this.props.placeholder} onPlaceSelected={this.onPlaceSelected} onRemove={this.removeStop} />
                                ))}
                            </div>
                        }
                        <div className="input-group__row input-group__row--adjust-sides">
                            <button type="button" className="input-btn" onClick={this.addStop} disabled={!this.state.routeStops || this.state.routeStops.length < 2}>
                                <span className="input-btn-icon">+</span>
                                <span className="input-btn-label">{this.props.addStopTitle}</span>
                            </button>
                        </div>
                    </React.Fragment>
                )}

                <div className="input-group__row input-group__row--adjust-sides">
                    <TravelLocation id={`${this.props.id}__destination`} name={`${this.props.id}__destination`} label={this.props.destinationTitle} locationType={this.props.locationType} placeholder={this.props.placeholder} onPlaceSelected={this.onFinalDestinationSelected} value={this.state.destination} disabled={!this.state.stopInputs || this.state.routeStops.length === 0} />
                </div>
            </div>
        );
    }

    renderRoundTripOptions() {
        if (this.props.roundTrip !== "optional" || !this.props.roundTripOptions) {
            return null;
        }

        const options = [];

        if (this.props.roundTripOptions.oneWay) {
            options.push({
                title: this.props.roundTripOptions.oneWay.title,
                value: false,
                checkedByDefault: !this.state.roundTrip
            });
        }
        if (this.props.roundTripOptions.roundTrip) {
            options.push({
                title: this.props.roundTripOptions.roundTrip.title,
                value: true,
                checkedByDefault: this.state.roundTrip
            });
        }

        return <RadioButtonList id={`${this.props.id}_roundTripOptions`} options={options} adjustMargins={true} onOptionChanged={this.onRoundTripChanged} />;
    }

    onOriginSelected(place) {
        const routeStops = Object.assign([], this.state.routeStops);
        
        if (!routeStops.length) {
            routeStops.unshift(place);
        }
        else {
            // Replace first stop on route
            routeStops.splice(0, 1, place);
        }

        this.setState({ routeStops, origin: place.name }, this.onRouteUpdated);
    }

    onFinalDestinationSelected(place) {
        const routeStops = Object.assign([], this.state.routeStops);
        
        if (!routeStops.length) {
            console.error("A start destination must be added first");
            return;
        }
        else if (routeStops.length === 1) {
            routeStops.push(place);
        }
        else {
            // Replace last stop on route
            routeStops.splice(routeStops.length - 1, 1, place);
        }

        this.setState({ routeStops, destination: place.name }, this.onRouteUpdated);
    }

    onPlaceSelected(place) {
        const routeStops = Object.assign([], this.state.routeStops);
        const stopInputs = Object.assign([], this.state.stopInputs);
        const inputIndex = stopInputs.findIndex((stop) => stop.id === place.id);
        const routeIndex = routeStops.findIndex((stop) => stop.id === place.id);

        if (routeIndex === -1) {
            // Insert stop before last stop (final destination)
            routeStops.splice(routeStops.length - 1, 0, place);
        }
        else {
            routeStops.splice(routeIndex, 1, place);
        }

        if (inputIndex !== -1) {
            const input = stopInputs[inputIndex];
            input.value = place.name;
            stopInputs.splice(inputIndex, 1, input);
        }

        this.setState({ routeStops }, this.onRouteUpdated);
    }

    onRoundTripChanged(value) {
        // Value from radio buttons are read as string, convert to proper boolean
        this.setState({ roundTrip: (value === "true") }, this.onRouteUpdated);
    }

    addStop() {
        const stopInputs = Object.assign([], this.state.stopInputs);

        let key;

        if (!stopInputs.length) {
            key = 0;
        }
        else {
            key = stopInputs[stopInputs.length - 1].key + 1;
        }

        const id = "stop_" + key;

        stopInputs.push({
            id: id,
            key,
            label: null,
            value: ""
        });

        this.setState({ stopInputs }, this.onRouteUpdated);
    }

    removeStop(id) {
        if (!this.state.stopInputs) {
            return;
        }

        const routeStops = Object.assign([], this.state.routeStops);
        const stopInputs = Object.assign([], this.state.stopInputs);
        const inputIndex = stopInputs.findIndex((stop) => stop.id === id);
        const routeIndex = routeStops.findIndex((stop) => stop.id === id);

        if (inputIndex !== -1) {
            stopInputs.splice(inputIndex, 1);
        }

        if (routeIndex !== -1) {
            routeStops.splice(routeIndex, 1);
        }

        this.setState({ stopInputs, routeStops }, this.onRouteUpdated);
    }

    onRouteUpdated() {
        let currentStop = null;
        let previousStop = null;
        const previousEmission = this.state.calculatedEmission;

        let totalDistance = 0.0;
        let emissionSum = 0.0;

        if (!this.state.routeStops || this.state.routeStops.length < 2) {
            this.onEmissionCalculated(0.0, 0.0, previousEmission);
            return;
        }

        for (const stop of this.state.routeStops) {
            if (!currentStop) {
                currentStop = stop;
                continue;
            }

            previousStop = currentStop;
            currentStop = stop;

            const distance = FlightDistanceCalculator.calculateDistance(previousStop.coordinates, currentStop.coordinates, 1);
            const calculatedEmission = this.calculateEmission(distance);

            totalDistance = totalDistance + distance;
            emissionSum = emissionSum + calculatedEmission;
        }

        const totalEmission = emissionSum * this.state.travellers;

        this.onEmissionCalculated(totalDistance, totalEmission, previousEmission);
    }

    onTravellersUpdated(count) {
        this.setState({
            travellers: count
        }, this.onRouteUpdated);
    }

    calculateEmission(distance) {
        if (!distance) {
            return 0.0;
        }

        const distanceEmissionFactor = this.getEmissionFactor(distance);
        
        if (!distanceEmissionFactor) {
            return 0.0;
        }

        let totalEmission = distanceEmissionFactor * distance;

        if (this.state.roundTrip) {
            totalEmission = totalEmission * 2;
        }

        return totalEmission;
    }

    getEmissionFactor(distance) {
        if (!distance || !this.props.emissionFactorLookup) {
            return 0.0;
        }

        const lookupResults = this.props.emissionFactorLookup.filter((item) => distance >= item.distance);

        if (!lookupResults || !lookupResults.length) {
            return 0.0;
        }

        if (lookupResults.length === 1) {
            return lookupResults[0].emissionFactor;
        }

        lookupResults.sort(this.sortEmissionFactorLookupDesc);

        const bestMatch = lookupResults[0];

        return bestMatch.emissionFactor;
    }

    sortEmissionFactorLookupDesc(a, b) {
        if (a.distance === b.distance) {
            return 0;
        }

        return a.distance > b.distance ? -1 : 1;
    }

    onEmissionCalculated(totalDistance, calculatedEmission, previousEmission) {
        this.setState({
            totalDistance,
            calculatedEmission
        }, () => {
            LocalStorageHelper.updateEntryInSection(this.props.sectionId, this.props.id, this.state);

            if (this.props.onEmissionUpdated) {
                this.props.onEmissionUpdated(calculatedEmission, previousEmission);
            }
        });
    }

    getDefaultState() {
        let roundTripDefaultOption = false;

        if (this.props.roundTrip === true) {
            roundTripDefaultOption = true;
        }
        else if (this.props.roundTrip === "optional"
            && !!this.props.roundTripOptions
            && this.props.roundTripOptions.roundTrip.checkedByDefault) {

            roundTripDefaultOption = true;
        }

        return {
            stopInputs: [],
            routeStops: [],
            origin: "",
            destination: "",
            travellers: 1,
            roundTrip: roundTripDefaultOption,
            totalDistance: 0.0,
            calculatedEmission: 0.0
        };
    }

    resetState() {
        const defaultState = this.getDefaultState();
        const prevState = this.state;

        this.setState(defaultState, () => {
            LocalStorageHelper.removeEntryInSection(this.props.sectionId, this.props.id);
            
            if (this.props.onEmissionUpdated) {
                this.props.onEmissionUpdated(defaultState.calculatedEmission, prevState.calculatedEmission);
            }
        });
    }
}