import React from "react";
import PropTypes from "prop-types";

class RadioButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: props.checked
        };

        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(newProps) {
        this.setState({ checked: newProps.checked });
    }

    render() {
        return (
            <p className="radio-option">
              <input type="radio" name={this.props.name} id={this.props.id} value={this.props.value} checked={this.state.checked} onChange={this.onChange} />
              <label htmlFor={this.props.id}>
                <span className="title">{this.props.title}</span>
                <span className="description">{this.props.description}</span>
              </label>
            </p>
        );
    }

    onChange(e) {
        const value = e.currentTarget.getAttribute("value");
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }
}

RadioButton.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    value: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string,
    onChange: PropTypes.func
};

export default RadioButton;