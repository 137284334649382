import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import tree from '../../shared/images/svg/tree-full.svg';

import Footer from '../Footer/Footer';

class Home extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: props.isLoading, 
            contentData: props.contentData
        };
    }

    render() {
        var dataRetention = localStorage.getItem('dataRetention');
        if(dataRetention) {
            var storedDays = dataRetention.substring(0,2);
            var storedMonth = dataRetention.substring(3,5);
            var storedYear = dataRetention.substring(6,11);
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1; 
            var yyyy = today.getFullYear();
            if(yyyy > Number(storedYear) || mm > Number(storedMonth) || (dd > Number(storedDays) && yyyy >= Number(storedYear) && mm >= Number(storedMonth)))
            {
                localStorage.clear();
            }
        }

        return (
            <div className="splash">
                <div className="container">
                    <h1 className="site-title">{this.state.contentData.startPage.title}</h1>
                    <p className="lead">{this.state.contentData.startPage.description}</p>
                    <div className="splash__entries">
                      <NavLink className="btn-path" to='/personal/energy'>Privat</NavLink>
                      <NavLink className="btn-path" to='/company/energy'>Företag</NavLink>
                    </div>
                    <div className="splash__graphics"><img src={tree} alt="Ett träd" /></div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default Home;
