import React, { Component } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';

import CategorySubSection from "./CategorySubSection";
import CategoryCart from './CategoryCart/CategoryCart';
import InputNumberLegacy from './../Form/Legacy/InputNumber/InputNumberLegacy';

import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import HeaderNav from './../Header/HeaderNav/HeaderNav';
import Tooltip from './../Form/Legacy/Tooltip/Tooltip';

import RadioButton2 from './../Form/Legacy/Button/RadioButton2';
import RadioButton3 from './../Form/Legacy/Button/RadioButton3';
import { LocalStorageHelper } from '../../Helpers/LocalStorageHelper';

class Category extends Component {
  _storageKey;

  constructor(props) {
    super(props);

    this._storageKey = `${props.parentSegment}-${props.categoryName}`;

    const storedState = LocalStorageHelper.getValueFromSectionEntry(this._storageKey, this._storageKey);

    if (storedState) {
      this.state = storedState;
    }
    else {
      this.state = {
        isLoading: true,
        data: props.params,
        header: props.dataheader,
        sectionAmount: 0,
        sectionCost: 0,
        isradio1active: localStorage.getItem('radiobutton2') === "radio1" || !localStorage.getItem('radiobutton2') ? true : false,
        isradio2active: localStorage.getItem('radiobutton2') === "radio2" ? true : false,
        isradio3active: localStorage.getItem('radiobutton2') === "radio3" ? true : false,
        isChecked: false,
        redirectUrl: "",
        redirect: false
      };
    }

    this.inputRef = React.createRef();
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleInputCalculation = this.handleInputCalculation.bind(this);
    this.handleUpdateCalculation = this.handleUpdateCalculation.bind(this);
    this.handleRadioButtonToggle = this.handleRadioButtonToggle.bind(this);
    this.handleThirdRadioButtonToggle = this.handleThirdRadioButtonToggle.bind(this);
    this.handleCheckboxToggle = this.handleCheckboxToggle.bind(this);
    this.onSelectChanged = this.onSelectChanged.bind(this);

    this.updateAmountAndCost = this.updateAmountAndCost.bind(this);
  }

  getPriceFromPriceTable(amount) {
    var priceTableData = this.props.contentData.priceTable.tiers;
    var price = 0;
    priceTableData.forEach(tier => {
      if (tier.max !== -1 && tier.min <= amount && amount <= tier.max) {
        price = tier.price;
        return price;
      }
      else if (tier.min < amount && tier.max === -1) {
        price = tier.price;
        return price;
      }
    });
    return price;
  }

  updateAmountAndCost(amount, tableAmount) {
    var priceFromPriceTable = this.getPriceFromPriceTable(tableAmount)
    var cost = (amount) * priceFromPriceTable;
    this.setState({
      sectionAmount: amount,
      sectionCost: cost
    });
    localStorage.setItem(this._storageKey + '-sectionAmount', amount);
    localStorage.setItem(this._storageKey + '-sectionCost', cost);

    LocalStorageHelper.updateEntryInSection(this._storageKey, this._storageKey, this.state);
  }

  onSelectChanged(newValue, oldValue) {
    let amount = this.state.sectionAmount
      ? Number(this.state.sectionAmount)
      : Number(localStorage.getItem(this._storageKey + "-sectionAmount"));

    if (oldValue !== null) {
      amount = amount - oldValue;
    }

    if (newValue !== null) {
      amount = amount + newValue;
    }

    if (amount < 0) {
      amount = 0;
    }

    let tableAmount = amount;

    if (amount < 1.0) {
      tableAmount = Math.ceil(amount);
    }

    this.updateAmountAndCost(amount, tableAmount);
  }

  handleInputCalculation(inputValue, inputTypeOfCalculation, decimalCount) {
    var amount;
    inputValue = Number(inputValue);
    decimalCount = Number(decimalCount);

    if (inputTypeOfCalculation === "add") {
      if (this.state.sectionAmount) {
        if (decimalCount) {
          amount = Number(this.state.sectionAmount.toFixed(decimalCount)) + inputValue;
        }
        else {
          amount = Number(this.state.sectionAmount) + inputValue;
        }
      }
      else {
        var sectionAmount2 = localStorage.getItem(this._storageKey + '-sectionAmount');
        amount = Number(sectionAmount2) + inputValue;
      }
    }
    else if (inputTypeOfCalculation === "subtract") {
      if (this.state.sectionAmount) {
        if (decimalCount) {
          amount = Number(this.state.sectionAmount.toFixed(decimalCount)) - inputValue;
        }
        else {
          amount = Number(this.state.sectionAmount) - inputValue;
        }
      }
      else {
        var sectionAmount3 = localStorage.getItem(this._storageKey + '-sectionAmount');
        amount = Number(sectionAmount3) - inputValue;
      }
  
      if (amount < 0) {
        amount = 0;
      }
    }
    else if (inputTypeOfCalculation === "update") {
      amount = inputValue;
    }

    var tableAmount;

    if (amount < 1.0) {
      tableAmount = Math.ceil(amount);
    }
    else {
      tableAmount = amount;
    }

    var priceFromPriceTable = this.getPriceFromPriceTable(tableAmount)
    var cost = (amount) * priceFromPriceTable;

    this.setState({
      sectionAmount: amount,
      sectionCost: cost
    });

    localStorage.setItem(this._storageKey + '-sectionAmount', amount);
    localStorage.setItem(this._storageKey + '-sectionCost', cost);

    LocalStorageHelper.updateEntryInSection(this._storageKey, this._storageKey, this.state);
  }

  handleUpdateCalculation(newValue, oldValue) {
    newValue = Number(newValue);
    oldValue = Number(oldValue);

    var sectionAmount = localStorage.getItem(this._storageKey + '-sectionAmount');

    if (!sectionAmount) {
      sectionAmount = 0;
    }

    sectionAmount = ((sectionAmount - oldValue) + newValue);

    var tableAmount;

    if (sectionAmount < 1.0) {
      sectionAmount = sectionAmount < 0 ? 0 : sectionAmount;
      tableAmount = Math.ceil(sectionAmount);
    }
    else {
      tableAmount = sectionAmount;
    }

    var priceFromPriceTable = this.getPriceFromPriceTable(tableAmount)
    var cost = (sectionAmount) * priceFromPriceTable;

    this.setState({ sectionAmount: sectionAmount, sectionCost: cost });

    localStorage.setItem(this._storageKey + '-sectionAmount', sectionAmount);
    localStorage.setItem(this._storageKey + '-sectionCost', cost);

    LocalStorageHelper.updateEntryInSection(this._storageKey, this._storageKey, this.state);
  }

  handleRadioButtonToggle(to, from) {
    var storedData = localStorage.getItem(this._storageKey);
    var arr = [];
    if (storedData) {
      arr = JSON.parse(storedData);
    }
    var name = to;
    var exclude = from;
    var wrapper1 = "radiobutton-wrapper0-" + name;
    var wrapper2 = "radiobutton-wrapper0-" + name;
    var wrapper3 = "radiobutton-wrapper0-" + name;
    var excludedWrapper = "radiobutton-wrapper0-" + exclude;

    if (wrapper1 === excludedWrapper) {
      excludedWrapper = wrapper1;
    }

    if (wrapper2 === excludedWrapper) {
      excludedWrapper = wrapper2;
    }

    if (wrapper3 === excludedWrapper) {
      excludedWrapper = wrapper3;
    }

    if (name === "radio1") {
      //get active panel and erase all values in inputs there.
      var childDivs = document.getElementById(excludedWrapper).getElementsByTagName('input');
      var totalValueToSubtract = 0;
      for (var i = 0; i < childDivs.length; i++) {
        var childDiv = childDivs[i];
        if (childDiv.type === 'number') {
          var oldValue = Number(childDiv.value);
        }
        // eslint-disable-next-line no-loop-func
        if (arr.find(input => input.key === childDiv.dataset.name)) {
          for (var ref in this.refs) {
            var curr = (this.refs[ref]);
            if (curr.props.dataname === childDiv.dataset.name) {
              curr.setState({ clicks: 0 });
            }
          }
          // eslint-disable-next-line no-loop-func
          var itemIndex = arr.findIndex(function (c) {
            return c.key === childDiv.dataset.name;
          });
          arr[itemIndex].value = 0;
          var emissionFactor = parseFloat(childDiv.dataset.id);
          var decimalCount = emissionFactor.toString().length - 2;
          if (decimalCount < 0) {
            decimalCount = 0;
          }
          totalValueToSubtract = totalValueToSubtract + Number((oldValue * emissionFactor).toFixed(decimalCount));
        }
      }
      this.handleInputCalculation(totalValueToSubtract, "subtract");
      localStorage.setItem(this._storageKey, JSON.stringify(arr));

      this.setState({ isradio1active: true });
      this.setState({ isradio2active: false });
      this.setState({ isradio3active: false });
    }
    else if (name === "radio2") {
      //get active panel and erase all values in inputs there.
      var childDivs2 = document.getElementById(excludedWrapper).getElementsByTagName('input');
      var totalValueToSubtract2 = 0;
      for (var y = 0; y < childDivs2.length; y++) {
        var childDiv2 = childDivs2[y];
        if (childDiv2.type === 'number') {
          var oldValue2 = Number(childDiv2.value);
        }
        // eslint-disable-next-line no-loop-func
        if (arr.find(input => input.key === childDiv2.dataset.name)) {
          for (var ref2 in this.refs) {
            var curr2 = (this.refs[ref2]);
            if (curr2.props.dataname === childDiv2.dataset.name) {
              curr2.setState({ clicks: 0 });
            }
          }
          // eslint-disable-next-line no-loop-func
          var itemIndex2 = arr.findIndex(function (c) {
            return c.key === childDiv2.dataset.name;
          });

          arr[itemIndex2].value = 0;

          var emissionFactor2 = Number(childDiv2.dataset.id);
          var decimalCount2 = emissionFactor2.toString().length - 2;

          if (decimalCount2 < 0) {
            decimalCount2 = 0;
          }

          totalValueToSubtract2 = totalValueToSubtract2 + Number((oldValue2 * emissionFactor2).toFixed(decimalCount2));
        }
      }
      this.handleInputCalculation(totalValueToSubtract2, "subtract");
      localStorage.setItem(this._storageKey, JSON.stringify(arr));

      this.setState({ isradio1active: false });
      this.setState({ isradio2active: true });
      this.setState({ isradio3active: false });
    }
    else if (name === "radio3") {
      //get active panel and erase all values in inputs there.
      var childDivs3 = document.getElementById(excludedWrapper).getElementsByTagName('input');
      var totalValueToSubtract3 = 0;
      for (var z = 0; z < childDivs3.length; z++) {
        var childDiv3 = childDivs3[z];
        if (childDiv3.type === 'number') {
          var oldValue3 = Number(childDiv3.value);
        }
        // eslint-disable-next-line no-loop-func
        if (arr.find(input => input.key === childDiv3.dataset.name)) {
          for (var ref3 in this.refs) {
            var curr3 = (this.refs[ref3]);
            if (curr3.props.label === childDiv3.dataset.name) {
              curr3.setState({ clicks: 0 });
            }
          }
          // eslint-disable-next-line no-loop-func
          var itemIndex3 = arr.findIndex(function (c) {
            return c.key === childDiv3.dataset.name;
          });
          arr[itemIndex3].value = 0;
          var emissionFactor3 = Number(childDiv3.dataset.id);
          var decimalCount3 = emissionFactor3.toString().length - 2;
          if (decimalCount3 < 0) {
            decimalCount3 = 0;
          }
          totalValueToSubtract3 = totalValueToSubtract3 + Number((oldValue3 * emissionFactor3).toFixed(decimalCount3));
        }
      }

      this.handleInputCalculation(totalValueToSubtract3, "subtract");
      localStorage.setItem(this._storageKey, JSON.stringify(arr));

      this.setState({ isradio1active: false });
      this.setState({ isradio2active: false });
      this.setState({ isradio3active: true });
    }
  }

  handleCheckboxToggle(e) {
    var name = e.target.name;
    var wrapper1 = "checkbox-wrapper_" + name + "-0";
    var wrapper2 = "checkbox-wrapper_" + name + "-1";
    var storedData = localStorage.getItem(this._storageKey);
    var arr = [];
    if (storedData) {
      arr = JSON.parse(storedData);
    }
    if (e.target.checked) {
      localStorage.setItem(e.target.name, e.target.checked);
      document.getElementById(wrapper1).setAttribute("class", "c-toggle-disable-panels__disabled");
      var childDivs = document.getElementById(wrapper1).getElementsByTagName('input');
      var totalValueToSubtract = 0;
      for (var i = 0; i < childDivs.length; i++) {
        var childDiv = childDivs[i];
        if (childDiv.type === 'number') {
          var oldValue = Number(childDiv.value);
        }
        // eslint-disable-next-line no-loop-func
        if (arr.find(input => input.key === childDiv.dataset.name)) {
          for (let ref in this.refs) {
            var curr = (this.refs[ref]);
            if (curr.props.dataname === childDiv.dataset.name) {
              curr.setState({ clicks: 0 });
            }
          }
          // eslint-disable-next-line no-loop-func
          var itemIndex = arr.findIndex(function (c) {
            return c.key === childDiv.dataset.name;
          });
          arr[itemIndex].value = 0;
          var emissionFactor = Number(childDiv.dataset.id);
          var decimalCount = emissionFactor.toString().length - 2;
          if (decimalCount < 0) {
            decimalCount = 0;
          }
          totalValueToSubtract = totalValueToSubtract + Number(oldValue * emissionFactor.toFixed(decimalCount));
        }
      }
      this.handleInputCalculation(totalValueToSubtract, "subtract", decimalCount);
      localStorage.setItem(this._storageKey, JSON.stringify(arr));

      document.getElementById(wrapper2).setAttribute("class", "c-toggle-disable-panels__enabled");
    }
    else {
      localStorage.setItem(e.target.name, e.target.checked);
      document.getElementById(wrapper2).setAttribute("class", "c-toggle-disable-panels__disabled");
      var childDivs2 = document.getElementById(wrapper2).getElementsByTagName('input');
      var totalValueToSubtract2 = 0;
      for (var y = 0; y < childDivs2.length; y++) {
        var childDiv2 = childDivs2[y];
        if (childDiv2.type === 'number') {
          var oldValue2 = Number(childDiv2.value);
        }
        // eslint-disable-next-line no-loop-func
        if (arr.find(input => input.key === childDiv2.dataset.name)) {
          for (let ref in this.refs) {
            var curr2 = (this.refs[ref]);
            if (curr2.props.dataname === childDiv2.dataset.name) {
              curr2.setState({ clicks: 0 });
            }
          }
          // eslint-disable-next-line no-loop-func
          var itemIndex2 = arr.findIndex(function (c) {
            return c.key === childDiv2.dataset.name;
          });
          arr[itemIndex2].value = 0;
          var emissionFactor2 = Number(childDiv2.dataset.id);
          var decimalCount2 = emissionFactor2.toString().length - 2;
          if (decimalCount2 < 0) {
            decimalCount2 = 0;
          }
          totalValueToSubtract2 = totalValueToSubtract2 + Number(oldValue2 * emissionFactor2.toFixed(decimalCount2));
        }
      }
      this.handleInputCalculation(totalValueToSubtract2, "subtract", decimalCount2);
      localStorage.setItem(this._storageKey, JSON.stringify(arr));
      document.getElementById(wrapper1).setAttribute("class", "c-toggle-disable-panels__enabled");
    }
  }

  handleThirdRadioButtonToggle(emissionFactor) {
    var originalAmount = Number(emissionFactor);
    var amount = 0;
    var price = 0;
    var cost = 0;

    amount = originalAmount;
    price = this.getPriceFromPriceTable(amount);
    cost = amount * price;
    this.setState({ sectionAmount: amount });

    localStorage.setItem(this._storageKey + '-sectionAmount', amount);
    localStorage.setItem(this._storageKey + '-sectionCost', cost);
    localStorage.setItem(this._storageKey, []);
    this.setState({ sectionCost: cost });
  }

  handleLinkClick(e) {
    if (!e || !e.target) {
      return;
    }

    e.preventDefault();
    e.nativeEvent.preventDefault();

    const url = e.target.getAttribute("href");

    this.props.history.push(url);
    this.scrollToTop(500);

    return false;
  }

  scrollToTop(scrollDuration) {
    var cosParameter = window.scrollY / 2,
      scrollCount = 0,
      oldTimestamp = performance.now();

    function step(newTimestamp) {
      scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));

      if (scrollCount >= Math.PI) {
        window.scrollTo(0, 0);
      }

      if (window.scrollY === 0) {
        return;
      }

      window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
  }

  RenderSections(dataSections, handleInputCalculation, handleRadioButtonToggle, handleThirdRadioButtonToggle) {
    let sectionIndex = 1000;
    let sections = dataSections;
    let arr = [];
    let i = 0;
    let y = 500;
    let hasTogglePanels = false;
    let isActivePanel = false;
    let radio1active = this.state.isradio1active;
    let radio2active = this.state.isradio2active;
    let radio3active = this.state.isradio3active;
    let isChecked = this.state.isChecked;
    let noRadioButtonPanels = 0;
    let shouldInsertWrapperForCheckboxGroup = false;

    sections.forEach(section => {
      let subSectionRadioButtonsMarkup = [];
      let subSectionMarkup = [];
      let sectionMetaDesc = [];
      var checkboxNo = 0;
      var radioBtnNo = 0;

      section.subSection.forEach(subSection => {
        // TODO - when there is time, refactor everything in this "if" statement
        // in the same way the "else" statement has been refactored into a separate component
        if (subSection.template && subSection.template === "special template") {
          if (subSection.templateType === "radio") {
            var radioBtnId = 1;
            var controlSections = subSection.controls;

            if (controlSections.length === 2) {
              noRadioButtonPanels = 2;
            }

            else if (controlSections.length === 3) {
              noRadioButtonPanels = 3;
            }

            var radioButtonsControlInfo = [];
            var radioButtonNo = 0;
            hasTogglePanels = true;

            controlSections.forEach(controlSection => {
              var controls = controlSection.controlSection.controls;
              var inputsArr = [];
              let subSec2 = [];

              controls.forEach(control => {
                if (control.type === "text") {
                  inputsArr.push(<InputNumberLegacy key={['input', y].join('_')} dataname={['input', y, control.title].join('_')} label={control.title} unit={control.unit} dataheader={this._storageKey} emissionfactor={control.emissionFactor} clicks={control.initialClicks} stepValue={control.stepValue} tooltip={control.metaDescription} myFunc={handleInputCalculation} updateFunc={this.handleUpdateCalculation} ref={['input', y].join('_')}></InputNumberLegacy>);
                  y++;
                }
              });

              if (controlSection.controlSection.title) {
                var controlSectionTitle = controlSection.controlSection.title;
                isActivePanel = false;

                if ((radio1active && radioButtonNo === 0) || (radio2active && radioButtonNo === 1) || (radio3active && radioButtonNo === 2)) {
                  isActivePanel = true;
                }

                var wrapperId3 = "radiobutton-wrapper" + radioBtnNo + "-radio" + radioBtnId;
                subSec2.push(<div key={['inputgrpheader', y].join('_')} className={(isActivePanel ? 'c-toggle-panels__panel--active' : 'c-toggle-panels__panel')}><div className='input-group-header'>{controlSectionTitle}</div><div className='input-group'>{inputsArr}</div></div>);
                subSectionMarkup.push(<div key={['radiobtnwrapper', y].join('_')} id={wrapperId3}>{subSec2}</div>)
                y++;
                radioButtonNo++;
              }
              else {
                var wrapperId4 = "radiobutton-wrapper" + radioBtnNo + "-radio" + radioBtnId;
                subSec2.push(<div key={['inputgrp', y].join('_')} className='input-group'>{inputsArr}</div>);
                subSectionMarkup.push(<div key={['inputgrp', y, wrapperId4].join('_')} id={wrapperId4}>{subSec2}</div>);
                y++;
                radioButtonNo++;
              }

              var newItem = {
                title: controlSection.title,
                description: controlSection.description,
                // Can't help the insane naming and nested depth of properties
                // without modifying big parts of the JSON data structure
                emissionFactor:
                  controlSection.controlSection && controlSection.controlSection.controls && controlSection.controlSection.controls.length > 0
                    ? controlSection.controlSection.controls[0].emissionFactor
                    : null
              };

              radioButtonsControlInfo.push(newItem)
              radioBtnId++;
            });

            if (noRadioButtonPanels === 2) {
              subSectionRadioButtonsMarkup.push(
                <RadioButton2
                  key={['radiobtn', y].join('_')}
                  radio1Title={radioButtonsControlInfo[0].title}
                  radio1Desc={radioButtonsControlInfo[0].description}
                  radio1EmissionFactor={radioButtonsControlInfo[0].emissionFactor}
                  radio2Title={radioButtonsControlInfo[1].title}
                  radio2Desc={radioButtonsControlInfo[1].description}
                  radio2EmissionFactor={radioButtonsControlInfo[1].emissionFactor}
                  myFunc2={handleRadioButtonToggle}
                />
              );
            }
            else if (noRadioButtonPanels === 3) {
              subSectionRadioButtonsMarkup.push(
                <RadioButton3
                  key={['radiobtn', y].join('_')}
                  radio1Title={radioButtonsControlInfo[0].title}
                  radio1Desc={radioButtonsControlInfo[0].description}
                  radio1EmissionFactor={radioButtonsControlInfo[0].emissionFactor}
                  radio2Title={radioButtonsControlInfo[1].title}
                  radio2Desc={radioButtonsControlInfo[1].description}
                  radio2EmissionFactor={radioButtonsControlInfo[1].emissionFactor}
                  radio3Title={radioButtonsControlInfo[2].title}
                  radio3Desc={radioButtonsControlInfo[2].description}
                  radio3EmissionFactor={radioButtonsControlInfo[2].emissionFactor}
                  onSelectionChanged={handleThirdRadioButtonToggle}
                />);
            }

            y++;
            radioBtnNo++;
          }

          if (subSection.templateType === "check") {
            var checkboxId = 0;
            var controlSections2 = subSection.controls;
            var checkboxPanelToEnable = 0;
            var defaultCheckedClassName = "c-toggle-disable-panels__disabled";
            shouldInsertWrapperForCheckboxGroup = true;

            controlSections2.forEach(controlSection => {
              var controls = controlSection.controlSection.controls;
              var inputsArr2 = [];
              let subSec = []
              var shouldBeEnabled = true;
              var checkboxName = "checkbox_" + checkboxNo;
              var isCheckedFromStorageText = localStorage.getItem(checkboxName);
              var isCheckedFromStorage = false;

              if (isCheckedFromStorageText === "true") {
                isCheckedFromStorage = true;
              }

              controls.forEach(control => {
                if (control.type === "check") {
                  if (isCheckedFromStorage) {
                    isChecked = true;
                    shouldBeEnabled = true;
                  }
                  else {
                    isChecked = false;
                  }

                  if (control.metaDescription) {
                    var metaDesc = control.metaDescription;
                    inputsArr2.push(<div key={['tooltipwrapper', y].join('_')} className="c-tooltip-wrapper"><label className="checkbox"><input type="checkbox" name={checkboxName} checked={isChecked} onChange={this.handleCheckboxToggle} />{control.title}</label><Tooltip content={metaDesc}></Tooltip></div>);
                    y++;
                  }
                  else {
                    inputsArr2.push(<div key={['checkbox', y].join('_')}><label className="checkbox"><input type="checkbox" name={checkboxName} checked={isChecked} onChange={this.handleCheckboxToggle} />{control.title}</label></div>);
                    y++;
                  }
                }

                if (control.type === "text") {
                  inputsArr2.push(<InputNumberLegacy key={['input', y].join('_')} dataname={['input', y, control.title].join('_')} label={control.title} unit={control.unit} dataheader={this._storageKey} emissionfactor={control.emissionFactor} clicks={control.initialClicks} stepValue={control.stepValue} tooltip={control.metaDescription} myFunc={handleInputCalculation} updateFunc={this.handleUpdateCalculation} ref={['input', y].join('_')}></InputNumberLegacy>);
                  y++;
                }
              });

              if (!isChecked && checkboxPanelToEnable === 1) {
                shouldBeEnabled = false;
              }

              if (controlSection.controlSection.title) {
                var controlSectionTitle = controlSection.controlSection.title;
                var inputClassName = shouldBeEnabled ? 'c-toggle-disable-panels__enabled' : defaultCheckedClassName;
                subSec.push(<div key={['inputgrp-header', y].join('_')}><div className='input-group-header'>{controlSectionTitle}</div><div className="input-group">{inputsArr2}</div></div>);
                y++;
                checkboxPanelToEnable++;

                if (shouldInsertWrapperForCheckboxGroup) {
                  var wrapperId = "checkbox-wrapper_checkbox_" + checkboxNo + "-" + checkboxId;
                  subSectionMarkup.push(<div key={['checkboxwrapper', wrapperId].join('_')} id={wrapperId} className={inputClassName}>{subSec}</div>)
                }
              }
              else {
                var inputClassName2 = shouldBeEnabled ? 'c-toggle-disable-panels__enabled' : defaultCheckedClassName;
                subSec.push(<div key={['inputgrp2', y].join('_')} className="input-group">{inputsArr2}</div>);
                y++;
                checkboxPanelToEnable++;

                if (shouldInsertWrapperForCheckboxGroup) {
                  var wrapperId2 = "checkbox-wrapper_checkbox_" + checkboxNo + "-" + checkboxId;
                  subSectionMarkup.push(<div key={['checkboxwrapper', wrapperId2].join('_')} id={wrapperId2} className={inputClassName2}>{subSec}</div>)
                }
              }
              checkboxId++;
            });

            checkboxNo++;
          }
        }
        else {
          subSectionMarkup.push(
            <CategorySubSection
              key={`${this._storageKey}_${i}`}
              id={`${this._storageKey}_${i}`}
              sectionId={this._storageKey}
              onEmissionUpdated={this.handleUpdateCalculation}
              {...subSection} />
          );
          i++;
        }

        if (section.metaDescription) {
          sectionMetaDesc.push(<Tooltip key={['tooltip', i].join('_')} content={section.metaDescription}></Tooltip>);
          i++;
        }
      });

      arr.push(<fieldset key={this._storageKey + sectionIndex} className={(hasTogglePanels ? 'c-toggle-panels' : '')}><h3>{section.title}{sectionMetaDesc}</h3><p dangerouslySetInnerHTML={{ __html: section.description }}></p>{subSectionRadioButtonsMarkup}{subSectionMarkup}</fieldset>);
      sectionIndex++;
    });

    return arr;
  }

  render() {
    var categoryData = this.props.contentData[this.props.contentDataKey].categories.find(x => x.categoryName === this.props.categoryName);
    const nextUrl = `/${this.props.parentSegment}/${this.props.nextCategory}`;
    let prevUrl;

    if (this.props.prevCategory && this.props.prevCategory.length) {
      prevUrl = `/${this.props.parentSegment}/${this.props.prevCategory}`;
    }
    else {
      prevUrl = "/";
    }

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectUrl} />;
    }

    return (
      <div>
        <Header title={this.props.contentData.startPage.title} contentData={this.props.contentData}></Header>
        <HeaderNav items={this.props.menuLinks} />
        <div className="content">
          <div className="container">
            <div className="content-header">
              <h1 className="page-title">{categoryData.title}</h1>
            </div>
            <section className="content-wrap">
              <main>
                <p className="excerpt">{categoryData.description}</p>
                <form>
                  {this.RenderSections(categoryData.sections, this.handleInputCalculation, this.handleRadioButtonToggle, this.handleThirdRadioButtonToggle)}
                </form>
                <div className="pagination">
                  <a href={prevUrl} onClick={this.handleLinkClick} className="btn btn-outline">Föregående</a>
                  <a href={nextUrl} onClick={this.handleLinkClick} className="btn btn-outline">Nästa</a>
                </div>
              </main>
              <aside>
                <CategoryCart sectionCost={this.state.sectionCost || 0} sectionAmount={this.state.sectionAmount || 0} dataHeader={this._storageKey} nextUrl={nextUrl} contentData={this.props.contentData} />
              </aside>
            </section>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

Category.contextTypes = {
  router: PropTypes.object,
  location: PropTypes.object
};

export default Category;