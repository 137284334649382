export class LocalStorageHelper {
    static updateEntryInSection(section, key, value) {
        const newEntry = { key, value };

        const entriesJson = localStorage.getItem(section);
        let entries = [];

        if (entriesJson && entriesJson.length) {
            entries = JSON.parse(entriesJson);
            let matchingIndex = -1;

            for (let i = 0; i < entries.length; i++) {
                if (entries[i].key === key) {
                    matchingIndex = i;
                    break;
                }
            }

            if (matchingIndex !== -1) {
                entries.splice(matchingIndex, 1);
            }
        }

        entries.push(newEntry);

        localStorage.setItem(section, JSON.stringify(entries));
    }

    static removeEntryInSection(section, key) {
        const entriesJson = localStorage.getItem(section);
        let entries = [];

        if (entriesJson && entriesJson.length) {
            entries = JSON.parse(entriesJson);
            let matchingIndex = -1;

            for (let i = 0; i < entries.length; i++) {
                if (entries[i].key === key) {
                    matchingIndex = i;
                    break;
                }
            }

            if (matchingIndex !== -1) {
                entries.splice(matchingIndex, 1);
            }
        }

        localStorage.setItem(section, JSON.stringify(entries));
    }

    static getValueFromSectionEntry(section, key) {
        const entriesJson = localStorage.getItem(section);
        let entries = [];

        if (entriesJson && entriesJson.length) {
            entries = JSON.parse(entriesJson);
            let matchingIndex = -1;

            for (let i = 0; i < entries.length; i++) {
                if (entries[i].key === key) {
                    matchingIndex = i;
                    break;
                }
            }

            if (matchingIndex !== -1) {
                const matchingEntry = entries[matchingIndex];

                if (matchingEntry && matchingEntry.value) {
                    return matchingEntry.value;
                }
            }
        }

        return null;
    }
}