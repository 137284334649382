import React, { Component } from 'react';

class Tooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e) => {
        if(this.node && this.node.contains(e.target)) {
            if(this.state.visible) {
                this.setState({
                    visible: false
                });
            }
            else {
                this.setState({
                    visible: true
                });
            }
        }
        else {
            this.setState({
                visible: false
            });
        }
    }

    render() {
        return (
            <div ref={node => this.node = node} className={this.state.visible ? "c-tooltip --active" : "c-tooltip"}>
                ? <div className="c-tooltip__body" dangerouslySetInnerHTML={{__html: this.props.content}}></div>
            </div>
        );
    }
}

export default Tooltip;