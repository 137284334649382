import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class HeaderNav extends Component {
  constructor(props) {
    super(props);

    this.isActive = this.isActive.bind(this);
    this.renderLinks = this.renderLinks.bind(this);
  }
  
  render() {
    return (
      <div className="categories">
        <div className="container">
          {this.renderLinks()}
        </div>
      </div>
    );
  }

  isActive(match, location) {
    return !!match && match.url === location.pathname;
  }

  renderLinks() {
    if (!this.props.items || !this.props.items.length) {
      return null;
    }

    var itemElements = [];

    for (var i = 0; i < this.props.items.length; i++) {
      var item = this.props.items[i];

      if (!!item.separator) {
        itemElements.push(<span key={i} className="btn-seperator"></span>);
      }
      else {
        itemElements.push(
          <NavLink key={i} className="btn btn-category" exact activeClassName="selected" isActive={this.isActive} to={item.url} dangerouslySetInnerHTML={{__html: item.text}}></NavLink>
        );
      }
    }

    return itemElements;
  }
}

export default HeaderNav;
