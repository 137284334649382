import React from "react";
import PropTypes from "prop-types";

import RadioButton from "./RadioButton";

class RadioButtonList extends React.Component {
    constructor(props) {
        super(props);

        this.onOptionSelected = this.onOptionSelected.bind(this);

        let defaultOption = "";

        for (var i = 0; i < props.options.length; i++) {
            const option = props.options[i];
            if (option.checkedByDefault) {
                defaultOption = option.value !== undefined && option.value !== null
                    ? option.value.toString()
                    : "";
            }
        }

        this.state = {
            selectedValue: defaultOption
        };
    }

    render() {
        const options = [];

        for (let i = 0; i < this.props.options.length; i++) {
            const id = `${this.props.id}_${i}`;
            const option = this.props.options[i];
            const optionValue = option.value !== undefined && option.value !== null ? option.value.toString() : "";

            const optionIsSelected = (this.state.selectedValue !== null && optionValue === this.state.selectedValue)
                || (this.state.selectedValue === null && option.checkedByDefault);

            options.push(
                <RadioButton
                    key={i}
                    id={id}
                    name={this.props.id}
                    title={option.title}
                    description={option.description}
                    value={optionValue}
                    checked={optionIsSelected}
                    onChange={this.onOptionSelected} />
            );
        }

        return (
            <div className={`col-${options.length} input-group__row input-group__row--horizontal ${this.props.adjustMargins ? "input-group__row--adjust-sides" : ""}`}>
                {options}
            </div>
        );
    }

    onOptionSelected(value) {
        this.setState({ selectedValue: value }, () => {
            if (this.props.onOptionChanged) {
                this.props.onOptionChanged(this.state.selectedValue);
            }
        });
    }
}

RadioButtonList.contextTypes = {
    id: PropTypes.string,
    options: PropTypes.array,
    adjustMargins: PropTypes.bool,
    onOptionChanged: PropTypes.func
};

export default RadioButtonList;