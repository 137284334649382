import React, { Component } from 'react';

let appContent = require('../../AppContent/appContent.json');

class Footer extends Component {
  render() {
    return (
    <div className="container">
      <div className="l-footer">
        <p>
          <span>{appContent.global.postalAddress}</span> | <span>Besöksadress: {appContent.global.visitingAddress}</span> | <span>Tel: {appContent.global.phoneNumber}</span>
        </p>
        <p>
        <span>Org.nr: {appContent.global.orgNr}</span> | <span><a href={`mailto:${appContent.global.email}`} title={`Kontakta ${appContent.global.email}`}>{appContent.global.email}</a></span>
        </p>
      </div>
    </div>
    );
  }
}

export default Footer;
