import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import CartDropdown2 from './CartDropdown/CartDropdown2';
import MenuButton from '../Form/Legacy/Button/MenuButton';
import OutsideHandler from '../Form/Legacy/OutsideHandler/OutsideHandler';
import logoSvg from "../../shared/images/svg/vi-skogen-logo-square.svg";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
        title: props.title,
        contentData: props.contentData
    };
  }

  render() {
    var amountText = " ton CO<sub>2</sub>e";
    var costText = "kr";

    return (
      <header className="topbar">
        <div className="container">
          <div className="logo">
            <div className="symbol">
              <NavLink exact to='/'>
                <img src={logoSvg} alt="Vi-skogen" width={68} height={74} />
              </NavLink>
            </div>
            <div className="text"><NavLink exact to='/'>{this.state.title}</NavLink></div>
          </div>
          <div className="c-menubar">
            <div className="c-menu-dropdown --cart">
              <OutsideHandler btnClassToHandle="c-menu-dropdown__button">
                <CartDropdown2 key="cartdropdown" contentData={this.state.contentData} amountText={amountText} costText={costText} />
              </OutsideHandler>
            </div>
            <div className="c-menu-dropdown --navigation">
              <OutsideHandler btnClassToHandle="c-menu-dropdown__button">
                <MenuButton title={amountText} description={costText} contentData={this.state.contentData} />
              </OutsideHandler>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
