import React, { Component } from 'react';

import menu from '../../../../shared/images/svg/icon-menu.svg';
import closeMenu from '../../../../shared/images/svg/icon-menu-close.svg';
import DropDownNav from '../../../Header/HeaderNav/DropDownNav';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      contentData: this.props.contentData
    };

    this.toggleVisible = this.toggleVisible.bind(this);
  }

  toggleVisible() {
    var isOpen = this.state.open;
    this.setState({ open: !isOpen });
  }

  render() {
    return (
      <button key="menubtn" onClick={this.toggleVisible} className={this.state.open ? "c-menu-dropdown__button --active" : "c-menu-dropdown__button"}>
        <svg className="c-icon --menu" role="img">
          <use xlinkHref={`${menu}#icon-menu`}></use>
        </svg>
        <svg className="c-icon --close" role="img">
          <use xlinkHref={`${closeMenu}#icon-menu-close`}></use>
        </svg>
      </button>, <div key="menudropdown" className="c-menu-dropdown__panel">
        <nav aria-label="navigering">
          <ul>
            <li><a href="/personal/energy/" title="Privat">Privat</a></li>
            <li><a href="/company/energy/" title="Företag">Företag</a></li>
          </ul>
        </nav>
        <nav aria-label="Externa länkar">
          <ul className="c-menu-dropdown__secondary-links">
            <DropDownNav contentData={this.state.contentData} />
          </ul>
        </nav>
      </div>
    );
  }
}

export default Button;