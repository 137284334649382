import React, { Component } from 'react';

class DropDownNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentData: props.contentData
        };
    }

    render() {
        let linkData = this.state.contentData.navigation.links;

        function RenderNavigationLinks(props) {
            let links = props.links;
            let linkArray = [];
            var y = 0;

            links.forEach(link => {
                linkArray.push(<li key={['listitem', y].join('_')}><a href={link.url} title={link.title}>{link.title}</a></li>);
                y++;
            });

        return linkArray;
        }
        
        return (
            <RenderNavigationLinks key="dropdownnav" links={linkData}></RenderNavigationLinks>
        );   
    }
}

export default DropDownNav;
