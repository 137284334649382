import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RadioButton2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: false,
      radio1Title: props.radio1Title,
      radio1Desc: props.radio1Desc,
      radio2Title: props.radio2Title,
      radio2Desc: props.radio2Desc,
      checked: localStorage.getItem('radiobutton2')
    };
  }
  handleClick() {
    this.setState({
      condition: !this.state.condition
    });
  }

  onCheckedChanged(e) {
    var selectedMatch = e.currentTarget.id;
    if(selectedMatch === "radio1") {
      this.props.myFunc2(selectedMatch, "radio2");
      this.setState({
        checked: selectedMatch
      });
      localStorage.setItem('radiobutton2', selectedMatch);
    }
    else {
      this.props.myFunc2(selectedMatch, "radio1");
      this.setState({
        checked: selectedMatch
      });
      localStorage.setItem('radiobutton2', selectedMatch);
    }
  }

  render() {
    return (
      <div className="input-group radio col-2">
          <p className="radio-option">
            <input type="radio" name="match" id="radio1" value="0" checked={this.state.checked === "radio1" || !this.state.checked} onChange={(e) => this.onCheckedChanged(e)} />
            <label htmlFor="radio1">
              <span className="title">{this.state.radio1Title}</span>
              <span className="description">{this.state.radio1Desc}</span>
            </label>
          </p>
          <p className="radio-option">
            <input type="radio" name="match" id="radio2" value="1" checked={this.state.checked === "radio2"} onChange={(e) => this.onCheckedChanged(e)} />
            <label htmlFor="radio2">
              <span className="title">{this.state.radio2Title}</span>
              <span className="description">{this.state.radio2Desc}</span>
            </label>
          </p>
      </div>
    );
  }
}

RadioButton2.protoTypes = {
  myFunc2: PropTypes.func,
};

export default RadioButton2;
