import React from "react";
import Tooltip from "./Legacy/Tooltip/Tooltip";

export class Select extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(e) {
        const value = e.currentTarget.value;

        this.setState({ value: value }, () => {
            if (this.props.onSelectChanged) {
                this.props.onSelectChanged(value);
            }
        });
    }

    render() {
        return (
            <div className="input-group__row input-group__row--adjust-sides">
                <div className="input-element__header">
                    {!!this.props.id && !!this.props.label &&
                        <label htmlFor={this.props.id}>{this.props.label}</label>
                    }
                    {!!this.props.tooltip &&
                        <Tooltip content={this.props.tooltip} />
                    }
                </div>
                <select className="c-select" id={this.props.id} onChange={this.onValueChanged} value={this.state.value}>
                    {this.props.options.map((option, key) => <option value={option.value} key={key}>{option.text}</option>)}
                </select>
            </div>
        );
    }
}